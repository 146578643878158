import React, { useEffect } from "react";
import usePaymentShop from '../hooks/usePaymentShop'
import Dialog from '@mui/material/Dialog';
import InputAdornment from '@mui/material/InputAdornment';
import { useSelector, useDispatch } from 'react-redux';
import FormInvoice from '../components/FormInvoice';
import Invoice from '../components/Invoice';
import PaymentOptions from '../components/PaymentOptions';
import Direction from '../components/Direction';
import FormDirection from '../components/FormDirection'
import TCBanks from '../components/TCBanks'
import FormatPayment from '../components/FormatPayment'
import BankPlataform from '../components/BankPlataform'
import StatusBar from '../components/StatusBar'
import { setOpen } from '../store/shopReducer';
import { Link } from 'react-router-dom'
const PaymentShop = () => {
  const personAddress = useSelector(({ shopReducer }) => shopReducer.personAddress);
  const step = useSelector(({ shopReducer }) => shopReducer.step);
  const dispatch = useDispatch();
  const open = useSelector(({ shopReducer }) => shopReducer.open);
  const { init, needInvoice, fiscalId, setFiscalId, searcFiscalID, fiscalData, fiscalD, postalCodes,postalCode, directionData, isAgree, setIsAgree, formatCurrency, cart, showParticipantsCards, setShowParticipantsCards, creditPaymentOptions, goToDepositFormat, handleInvoice, handleForm, handleInvoiceForm, handleInvoiceData, handleDirectionData, handleStep, goToCardPayment, creditPaymentOption, setCreditPaymentOption, handleDirectionForm, directionItem, setDirectionItem, handlePostalCodeDirectionData, handlePostalCodeInvoiceData, totalAmount, person, format, isFormatPayment, currencyCode, handleDirectionStep, countries, cuponActive, setCuponActive} = usePaymentShop();
    useEffect(() => {
      init('Tienda ASC', 'Datos de facturación', 2)
    }, [])
    return (
      <>
        <StatusBar />
        {step === 3 &&
          <>
            <Invoice 
              handleInvoice={handleInvoice} 
              fiscalId={fiscalId} 
              setFiscalId={setFiscalId} 
              InputAdornment={InputAdornment} 
              searcFiscalID={searcFiscalID} 
              handleForm={handleForm} 
              needInvoice={needInvoice}
              fiscalD={fiscalD}
            />
            {needInvoice !== null &&
              <div className="flex flex-col sm:flex-row justify-center">
                <Link 
                  to={'/'} 
                  className="border-[1px] border-blueDark hover:border-blueDarken-600 text-blueDarken bg-white disabled:border-grayDisabled disabled:text-textgrayDisabled font-medium text-white px-[1rem] py-[0.375rem] h-fit rounded-[4px] items-center uppercase text-[0.875rem] leading-[1.75] tracking-[0.02857em] space-x-3 mx-[10px]"
                >
                  Regresar a la tienda</Link>
                <button 
                disabled={needInvoice === null &&  fiscalD === null} 
                onClick={() => handleStep('direction')} 
                className="bg-blueDarken disabled:bg-grayDisabled disabled:text-textgrayDisabled font-medium text-white inline-flex px-[1rem] py-[0.375rem] h-fit rounded-[4px] items-center uppercase text-[0.875rem] leading-6 tracking-[0.02857em] space-x-3 mx-[10px]"
              >
                Siguiente</button>
              </div>
            }
          </>
        }
        {step === 2 &&
          <>
            <Direction 
              personAddress={personAddress} 
              directionItem={directionItem} 
              setDirectionItem={setDirectionItem} 
              needInvoice={needInvoice} 
              handleStep={handleStep} 
              directionData={directionData}
              postalCodes={postalCodes}
              handleDirectionData={handleDirectionData}
              handleDirectionForm={handleDirectionForm}
              handlePostalCodeDirectionData={handlePostalCodeDirectionData} 
              countries={countries}
              cuponActive={cuponActive}
              setCuponActive={setCuponActive}
            />
            {/* {needInvoice !== null && <div className="flex flex-col sm:flex-row justify-center">
              <button 
                onClick={() => handleStep('invoice')} 
                className="border-[1px] border-blueDark hover:border-blueDarken-600 text-blueDarken bg-white disabled:border-grayDisabled disabled:text-textgrayDisabled font-medium text-white px-[1rem] py-[0.375rem] h-fit rounded-[4px] items-center uppercase text-[0.875rem] leading-[1.75] tracking-[0.02857em] space-x-3 mx-[10px]"
              >Regresar</button>
              <button 
                disabled={directionItem === null ? true : false }
                onClick={() => handleStep('pay')} 
                className="bg-blueDarken disabled:bg-grayDisabled disabled:text-textgrayDisabled font-medium text-white inline-flex px-[1rem] py-[0.375rem] h-fit rounded-[4px] items-center uppercase text-[0.875rem] leading-6 tracking-[0.02857em] space-x-3 mx-[10px]"
              >
              Siguiente</button>
            </div>} */}
           <div className="flex flex-row justify-center">
            <Link 
              to={'/'} 
              className="border-[1px] border-blueDark hover:border-blueDarken-600 text-blueDarken bg-white disabled:border-grayDisabled disabled:text-textgrayDisabled font-medium text-white px-[1rem] py-[0.375rem] h-fit rounded-[4px] items-center uppercase text-[0.875rem] leading-[1.75] tracking-[0.02857em] space-x-3 mx-[10px]"
            >Regresar</Link>
              <button 
                disabled={directionData.countryCode === '' || directionData.email === '' || directionData.internalNumber === '' || directionData.locality === '' || directionData.number === '' || directionData.phoneNumber === '' || directionData.postalCode === '' || directionData.reference === '' || directionData.state === '' || directionData.street === '' ? true : false }
                onClick={() => handleDirectionStep('pay')} 
                className="bg-blueDarken disabled:bg-grayDisabled disabled:text-textgrayDisabled font-medium text-white inline-flex px-[1rem] py-[0.375rem] h-fit rounded-[4px] items-center uppercase text-[0.875rem] leading-6 tracking-[0.02857em] space-x-3 mx-[10px]"
              >
              Siguiente</button>
            </div>
          </>
        }
        {step === 4 &&
          <>
            {cart && <PaymentOptions 
              setIsAgree={setIsAgree}
              cart={cart}
              goToDepositFormat={goToDepositFormat}
              currencyCode={currencyCode}
              formatCurrency={formatCurrency}
              creditPaymentOption={creditPaymentOption}
              creditPaymentOptions={creditPaymentOptions}
              setCreditPaymentOption={setCreditPaymentOption}
              totalAmount={totalAmount}
              // handleChange={handleChange}
              isAgree={isAgree}
              handleForm={handleForm}
              handleStep={handleStep}
              goToCardPayment={goToCardPayment}
              directionData={directionData}
              cuponActive={cuponActive}
            />}
            <div className="row mx-2 mt-4 mb-4">
              <button
                className="border-[1px] border-blueDark hover:border-blueDarken-600 text-blueDarken bg-white disabled:border-grayDisabled disabled:text-textgrayDisabled font-medium text-white px-[1rem] py-[0.375rem] h-fit rounded-[4px] items-center uppercase text-[0.875rem] leading-[1.75] tracking-[0.02857em] space-x-3 mx-[10px]"
                onClick={() => handleStep('direction')} 
              >
                Regresar
              </button>
            </div>
          </>
        }
        {step === 5 &&
          isFormatPayment 
          ?
            cart && <FormatPayment 
              formatCurrency={formatCurrency}
              cart={cart}
              person={person}
              totalAmount={totalAmount}
              currentUserCountryCode={'MX'}
              format={format}
              // pdfUrl={pdfUrl}
              creditPaymentOptions={creditPaymentOptions}
              // currencyPayment={currencyPayment}
              // paymentURL={paymentURL}
              handleApprovePaypal={null}
              setError={null}
            />
          :
            cart && <BankPlataform 
              creditPaymentOption={creditPaymentOption}
              currencyPayment={cart[0].price.currencyCode}
              format={format}
            />
          
        }
        <Dialog
          open={open.open ? true : false}
          onClose={() => dispatch(setOpen(false))}
          fullWidth={true}
          maxWidth={'lg'}
          sx={{ '& .css-cyxlny-MuiPaper-root-MuiDialog-paper': { backgroundColor: 'transparent', borderRadius: '15px', overflowY: 'hidden' }}}
        >
          {open.form === 'invoice' && 
            <FormInvoice
              isShop={true} 
              fiscalData={fiscalData}
              handleInvoiceForm={handleInvoiceForm}
              handleInvoiceData={handleInvoiceData}
              postalCode={postalCode}
              handleForm={handleForm} 
              handlePostalCodeInvoiceData={handlePostalCodeInvoiceData} 
            />
          }
          {open.form === 'direction' && 
            <FormDirection 
              type={open.type} 
              isShop={true} 
              
            />
          }
          {open.form === 'creditCard' && 
            <TCBanks />
          }
        </Dialog>
      </>
    )
}
export default PaymentShop;
