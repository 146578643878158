import React from "react";
const BankPlataform = ({creditPaymentOption, currencyPayment, format}) => {
  return (
    <>
      {creditPaymentOption && 
        <h4 className="text-[1.2rem] font-semibold leading-none mt-[2rem] mb-[1rem]">
          Pago en plataforma de Banco a {creditPaymentOption} meses
        </h4>
      }
      {currencyPayment !== 'MXN' &&
        <h4>
          Pago en plataforma de Banco será realizado en Dólar Americano
        </h4>
      }
      {creditPaymentOption === false && currencyPayment === 'MXN' &&
        <h4>
          Pago en plataforma de Banco
        </h4>
      }
      {format !== null && <div className="row">
          <div className="col-lg text-center">
            <iframe
              className="frame"
              name="payment"
              width="100%"
              height="700px"
              src={format.data.URL}
              title="Format Payment"
            >
              <p>Tu browser no soporta iFrames</p>
            </iframe>
          </div>
        </div>
      }
    </>
  )
}
export default BankPlataform
