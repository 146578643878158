import React, { useState } from "react";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const Direction = ({setDirectionItem, needInvoice, handleStep, directionData, postalCodes, handleDirectionData, countries, cuponActive, setCuponActive}) => {
  // console.log(cuponActive)
  // const [cuponActive, setCuponActive] = useState(false)
  return (
    <>
      <div className="row mx-2 mt-[1.3rem]">
        <h3 className="text-[1.5rem] font-bold">
          Ingresa tu dirección de entrega.
        </h3>
      </div>
      {/* <div className="mb-[0.6rem]">
        <p>
          <br />
          Puedes agregarlos ahora
        </p>
      </div> */}
      <h3 className='text-[1.2rem] font-semibold mb-[1.7rem] pl-[0.5rem]'>Datos del comprador</h3>
      {/* <div className={`bg-white shadow-xl rounded-[15px] px-[20px] pt-[18px] pb-[20px] mt-[20px] md:mt-[0px] mb-4 text-blackASC min-w-[80%] lg:h-fit w-full flex justify-center flex-col overflow-y-auto`}> */}
        
        <div className="flex flex-col sm:flex-row items-center w-full mb-[12px] justify-center">
          <div className="flex flex-row items-center w-full sm:w-[50%] mb-[0.6rem] sm:mb-0">
            <FormControl className="bg-white" fullWidth>
              <TextField
                required
                id="name"
                label="*Nombre:"
                variant="outlined"
                value={directionData.name}
                onChange={(e) => handleDirectionData('name', e)}
              />
            </FormControl>
            <CheckCircleIcon
              className={`ml-[5px] ${directionData.name !== null && directionData.name !== '' ? 'opacity-100' : 'opacity-0' }`}
              style={{fill: "#82CA9C"}} 
            />
          </div>
          <p className='w-full sm:w-full sm:w-[239px] ml-[12px] pb-0 text-left mb-[0.6rem] sm:mb-0 text-left italic'>Ingresa el nombre de quien recibe el paquete.</p>
        </div>
        <div className="flex flex-col sm:flex-row items-center w-full mb-[12px] justify-center">
          <div className="flex flex-row items-center w-full sm:w-[50%] mb-[0.6rem] sm:mb-0">
            <FormControl className="bg-white" fullWidth>
              <TextField
                required
                id="name"
                label="Escuela:"
                variant="outlined"
                value={directionData.school}
                onChange={(e) => handleDirectionData('school', e)}
              />
            </FormControl>
            <CheckCircleIcon
              className={`ml-[5px] ${directionData.school !== null && directionData.school !== '' ? 'opacity-100' : 'opacity-0' }`}
              style={{fill: "#82CA9C"}} 
            />
          </div>
          <p className='w-full sm:w-full sm:w-[239px] ml-[12px] pb-0 text-left mb-[0.6rem] sm:mb-0 text-left italic'>Ingresa el nombre de la escuela.</p>
        </div>
        <div className="flex flex-col sm:flex-row items-center w-full mb-[12px] justify-center">
          <div className="flex flex-row items-center w-full sm:w-[50%] mb-[0.6rem] sm:mb-0">
            <FormControl className="bg-white" fullWidth>
              <TextField
                required
                id="email"
                label="CORREO ELECTRÓNICO:"
                variant="outlined"
                value={directionData.email}
                onChange={(e) => handleDirectionData('email', e)}
              />
            </FormControl>
            <CheckCircleIcon
              className={`ml-[5px] ${directionData.email !== null && directionData.email !== '' ? 'opacity-100' : 'opacity-0' }`}
              style={{fill: "#82CA9C"}} 
            />
          </div>
          <p className='w-full sm:w-full sm:w-[239px] ml-[12px] pb-0 text-left mb-[0.6rem] sm:mb-0 text-left italic'>Ingresa tu correo electrónico.</p>
        </div>
        <div className="flex flex-col sm:flex-row items-center w-full mb-[12px] justify-center">
          <div className="flex flex-row items-center w-full sm:w-[50%] mb-[0.6rem] sm:mb-0">
            <FormControl className="bg-white" fullWidth>
              <TextField
                required
                id="phoneNumber"
                label="NÚMERO DE TELÉFONO:"
                variant="outlined"
                value={directionData.phoneNumber}
                onChange={(e) => handleDirectionData('phoneNumber', e)}
              />
            </FormControl>
            <CheckCircleIcon
              className={`ml-[5px] ${directionData.phoneNumber !== null && directionData.phoneNumber !== '' ? 'opacity-100' : 'opacity-0' }`}
              style={{fill: "#82CA9C"}} 
            />
          </div>
          <p className='w-full sm:w-[239px] ml-[12px] pb-0 text-left mb-[0.6rem] sm:mb-0 italic'>Ingresa el numero de teléfono.</p>
        </div>
        <div className="flex flex-col sm:flex-row items-center w-full mb-[12px] justify-center">
          <div className="flex flex-row items-center w-full sm:w-[50%] mb-[0.6rem] sm:mb-0">
            <FormControl className='bg-white' fullWidth>
              <InputLabel id="demo-simple-select-label" className="" >Selecciona tu País</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                label="Selecciona tu País"
                id="demo-simple-select"
                value={directionData.countryCode}
                onChange={(e) => handleDirectionData('countryCode', e)}
              >
                {countries && countries.map((option, index) => {
                  return (
                    <MenuItem key={index} value={option.value}>
                      {option.text}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <CheckCircleIcon 
              className={`ml-[5px] ${directionData.countryCode !== null && directionData.countryCode !== '' ? 'opacity-100' : 'opacity-0' }`}
              style={{fill: "#82CA9C"}} />
          </div>
          <p className='w-full sm:w-[239px] ml-[12px] pb-0 text-left mb-[0.6rem] sm:mb-0 italic'>Selecciona el país de entrega.</p>
        </div>
        <div className="flex flex-col sm:flex-row items-center w-full mb-[12px] justify-center">
          <div className="flex flex-row items-center w-full sm:w-[50%] mb-[0.6rem] sm:mb-0">
            <FormControl className="bg-white" fullWidth>
              <TextField
                required
                id="company_name"
                label="Estado"
                variant="outlined"
                onChange={(e) => handleDirectionData('state', e)}
                value={directionData.state}
                disabled={directionData.countryCode === '' ? true : false}
              />
            </FormControl>
            <CheckCircleIcon
              className={`ml-[5px] ${directionData.state !== null && directionData.state !== '' ? 'opacity-100' : 'opacity-0' }`}
              style={{fill: "#82CA9C"}} 
            />
          </div>
          <p className='w-full sm:w-[239px] ml-[12px] pb-0 text-left mb-[0.6rem] sm:mb-0 italic'>Selecciona el estado de entrega</p>
        </div>
        <div className="flex flex-col sm:flex-row items-center w-full mb-[12px] justify-center">
          <div className="flex flex-row items-center w-full sm:w-[50%] mb-[0.6rem] sm:mb-0">
            <FormControl className="bg-white" fullWidth>
              <TextField
                required
                id="company_name"
                label="ALCALDÍA/MUNICIPIO:"
                variant="outlined"
                value={directionData.locality}
                onChange={(e) => handleDirectionData('locality', e)}
                disabled={directionData.state === '' ? true : false}
              />
            </FormControl>
            <CheckCircleIcon 
              className={`ml-[5px] ${directionData.locality !== null && directionData.locality !== '' ? 'opacity-100' : 'opacity-0' }`}
              style={{fill: "#82CA9C"}} 
            />
          </div>
          <p className='w-full sm:w-[239px] ml-[12px] pb-0 text-left mb-[0.6rem] sm:mb-0 italic'>Selecciona alcaldía/municipio de entrega</p>
        </div>
        <div className="flex flex-col sm:flex-row items-center w-full mb-[12px] justify-center">
          <div className="flex flex-row items-center w-full sm:w-[50%] mb-[0.6rem] sm:mb-0">
            <FormControl className="bg-white" fullWidth>
              <TextField
                required
                id="company_name"
                label="Codigo Postal"
                variant="outlined"
                value={directionData.postalCode}
                onChange={(e) => handleDirectionData('postalCode', e)}
                disabled={directionData.locality === '' ? true : false}
              />
            </FormControl>
            <CheckCircleIcon 
              className={`ml-[5px] ${directionData.postalCode !== null && directionData.postalCode !== '' ? 'opacity-100' : 'opacity-0' }`}
              style={{fill: "#82CA9C"}} 
            />
          </div>
          <p className='w-full sm:w-[239px] ml-[12px] pb-0 text-left mb-[0.6rem] sm:mb-0 italic'>Ingresa el código postal.</p>
        </div>
        {/* <div className="flex flex-col sm:flex-row items-center w-full mb-[12px] justify-center">
          <div className="flex flex-row items-center w-full sm:w-[50%] mb-[0.6rem] sm:mb-0">
            <FormControl className='bg-white' fullWidth>
              <InputLabel id="demo-simple-select-label" className="" >Selecciona tu Colonia</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={directionData.neighborhood}
                onChange={(e) => handleDirectionData('neighborhood', e)}
              >
                {postalCodes.map((option, index) => {
                  return (
                    <MenuItem key={index} value={option}>
                      {option.neighborhood}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <CheckCircleIcon 
              className={`ml-[5px] ${directionData.neighborhood !== null && directionData.neighborhood !== '' ? 'opacity-100' : 'opacity-0' }`}
              style={{fill: "#82CA9C"}} />
          </div>
          <p className='w-full sm:w-[239px] ml-[12px] pb-0 text-left mb-[0.6rem] sm:mb-0 italic'>Ingresa el código postal.</p>
        </div> */}
        <div className="flex flex-col sm:flex-row items-center w-full mb-[12px] justify-center">
          <div className="flex flex-row items-center w-full sm:w-[50%] mb-[0.6rem] sm:mb-0">
            <FormControl className="bg-white" fullWidth>
              <TextField
                required
                id="company_name"
                label="CALLE:"
                variant="outlined"
                value={directionData.street}
                onChange={(e) => handleDirectionData('street', e)}
              />
            </FormControl>
            <CheckCircleIcon 
              className={`ml-[5px] ${directionData.street !== '' ? 'opacity-100' : 'opacity-0' }`}
              style={{fill: "#82CA9C"}} />
          </div>
          <p className='w-full sm:w-[239px] ml-[12px] pb-0 text-left mb-[0.6rem] sm:mb-0 italic'>Ingresa la calle.</p>
        </div>
        <div className="flex flex-col sm:flex-row items-center w-full mb-[12px] justify-center">
          <div className="flex flex-row items-center w-full sm:w-[50%] mb-[0.6rem] sm:mb-0">
            <FormControl className="bg-white mr-[5px!important]" fullWidth>
              <TextField
                required
                id="company_name"
                label="NÚMERO EXTERIOR:"
                variant="outlined"
                value={directionData.internalNumber}
                onChange={(e) => handleDirectionData('internalNumber', e)}
              />
            </FormControl>
            <FormControl className="bg-white ml-[5px!important]" fullWidth>
              <TextField
                required
                id="company_name"
                label="NÚMERO INTERIOR:"
                variant="outlined"
                value={directionData.number}
                onChange={(e) => handleDirectionData('number', e)}
              />
            </FormControl>
            <CheckCircleIcon 
              className={`ml-[5px] ${directionData.internalNumber !== '' && directionData.number !== '' ? 'opacity-100' : 'opacity-0' }`}
              style={{fill: "#82CA9C"}} />
          </div>
          <p className='w-full sm:w-[239px] ml-[12px] pb-0 text-left mb-[0.6rem] sm:mb-0 italic'>Ingresa el número del domicilio.</p>
        </div>
        <div className="flex flex-col sm:flex-row items-center w-full mb-[12px] justify-center">
          <div className="flex flex-row items-center w-full sm:w-[50%] mb-[0.6rem] sm:mb-0">
            <FormControl className="bg-white" fullWidth>
              <TextField
                required
                id="company_name"
                label="REFERENCIAS:"
                variant="outlined"
                value={directionData.reference}
                onChange={(e) => handleDirectionData('reference', e)}
              />
            </FormControl>
            <CheckCircleIcon 
              className={`ml-[5px] ${directionData.reference !== '' ? 'opacity-100' : 'opacity-0' }`}
              style={{fill: "#82CA9C"}} />
          </div>
          <p className='w-full sm:w-[239px] ml-[12px] pb-0 text-left mb-[0.6rem] sm:mb-0 italic'></p>
        </div>
        <div className="flex flex-col sm:flex-row items-center w-full mb-[12px] justify-center">
          <FormControlLabel className="w-full justify-start sm:justify-center" control={<Checkbox checked={cuponActive === true ? true : false} onClick={()=> setCuponActive(!cuponActive)} />} label="Agregar cúpon de descuento" />
        </div>
        {cuponActive && <div className="flex flex-col sm:flex-row items-center w-full mb-[12px] justify-center">
         
            <div className="flex flex-row items-center w-full sm:w-[50%] mb-[0.6rem] sm:mb-0">
              <FormControl className="bg-white" fullWidth>
                <TextField
                  required
                  id="company_name"
                  label="Cúpon:"
                  variant="outlined"
                  value={directionData.cupons}
                  onChange={(e) => handleDirectionData('cupons', e)}
                />
              </FormControl>
              {/* <CheckCircleIcon 
                className={`ml-[5px] ${directionData.reference !== '' ? 'opacity-100' : 'opacity-0' }`}
              style={{fill: "#82CA9C"}} /> */}
            </div>
          </div>
        }
        
        {/* <div className='flex mx-auto'>
          <button 
            onClick={()=> handleForm(false, null, null, null, null)}
            className='mx-[5px] border-[1px] border-blueDark hover:border-blueDarken-600 text-blueDarken bg-white disabled:border-grayDisabled disabled:text-textgrayDisabled font-medium text-white px-[1rem] py-[0.375rem] h-fit rounded-[4px] items-center uppercase text-[0.875rem] leading-[1.75] tracking-[0.02857em] space-x-3 mx-[10px]'>Cancelar</button>
          <button onClick={()=> handleDirectionForm(type)} className="bg-blueDarken disabled:bg-grayDisabled disabled:text-textgrayDisabled font-medium text-white inline-flex px-[1rem] py-[0.375rem] h-fit rounded-[4px] items-center uppercase text-[0.875rem] leading-6 tracking-[0.02857em] space-x-3 mx-[10px]">Enviar Datos</button>
        </div> */}
      {/* </div> */}
    </>


  )
}
export default Direction

