// import 'simplebar/src/simplebar.css';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux'
import store from './store';
import { ToastProvider } from 'react-toast-notifications';
import Politics from './app/Politics'
import TermsConditions from './app/TermsConditions'
import Shop from './app/Shop'
import PaymentShop from './app/PaymentShop'
import NotFound from './app/NotFound'
// import App from './App';
import './index.css';
import Layout from './components/LayoutShop';
ReactDOM.render(
  <Provider store={store}>
      <ToastProvider >
        <BrowserRouter>
          <Switch>  
            <Route exact path="/termns-conditions" component={TermsConditions} />
            <Route exact path="/politica" component={Politics} />
            <Layout>
              <Route exact path="/" component={Shop}  />
              <Route exact path="/pay" component={PaymentShop} />
              {/* <Route path="*" component={NotFound} /> */}
            </Layout>
            
          </Switch>
        </BrowserRouter>
      </ToastProvider>
  </Provider>,
  document.getElementById('root')
);
// reportWebVitals();