// export default => {
export const setUserUuid = (uuid) => {
  localStorage.setItem('userUuid', uuid);
}
export const setIsAsc = (isAsc) => {
  localStorage.setItem('isAsc', isAsc);
}
export const setFiscalData = (fiscalData) => {
  localStorage.setItem('fiscalData', fiscalData);
}
export const setOrderShippingId = (orderShippingId) => {
  localStorage.setItem('orderShippingId', orderShippingId);
}
export const setPayingCart = (payingCart) => {
  localStorage.setItem('payingCart', payingCart);
}
export const setDepositFormat = (depositFormat) => {
  localStorage.setItem('depositFormat', depositFormat);
}
export const setPaymentUUID = (paymentUUID) => {
  localStorage.setItem('paymentUUID', paymentUUID);
}
export const setCardPaymentVoucher = (cardPaymentVoucher) => {
  localStorage.setItem('cardPaymentVoucher', cardPaymentVoucher);
}
export const setPaymentOrigin = (paymentOrigin) => {
  localStorage.setItem('paymentOrigin', paymentOrigin);
}
// Getters
export const getUserUuid = () => {
  return localStorage.getItem('userUuid');
}
export const getIsAsc = () => {
  return localStorage.getItem('isAsc');
}
export const getFiscalData = () => {
  return localStorage.getItem('fiscalData');
}
export const getOrderShippingId = () => {
  return localStorage.getItem('orderShippingId');
}
export const getPayingCart = () => {
  return localStorage.getItem('payingCart');
}
export const getDepositFormat = () => {
  return localStorage.getItem('depositFormat');
}
export const getPaymentUUID = () => {
  return localStorage.getItem('paymentUUID');
}
export const getCardPaymentVoucher = () => {
  return localStorage.getItem('cardPaymentVoucher');
}
export const getPaymentOrigin = () => {
  return localStorage.getItem('paymentOrigin');
}
// Remove
export const remove = (key) => {
  localStorage.removeItem(key);
}
