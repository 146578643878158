import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import Select from '@mui/material/Select';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InputAdornment from '@mui/material/InputAdornment';
const FormDirection = ({isShop, type, handleDirectionData, directionData, postalCodes, handleForm, handleDirectionForm, handlePostalCodeDirectionData, }) => {
  const [cuponActive, handleActiveCupon] = useState(false)
  return ( 
    <div className={`bg-white shadow-xl rounded-[15px] px-[20px] pt-[18px] pb-[20px] mt-[20px] md:mt-[0px] mb-4 text-blackASC min-w-[80%] lg:h-fit w-full flex justify-center flex-col overflow-y-auto`}>
      <h3 className='text-center my-[15px]'>ACTUALIZAR DIRECCIÓN DE ENTREGA</h3>
      <div className="flex flex-row items-center w-full mb-[12px] justify-center">
        <div className="flex flex-row items-center w-[50%]">
          <FormControl className="bg-white" fullWidth>
            <TextField
              required
              id="email"
              label="CORREO ELECTRÓNICO:"
              variant="outlined"
              value={directionData.email}
              onChange={(e) => handleDirectionData('email', e)}
            />
          </FormControl>
          <CheckCircleIcon
            className={`ml-[5px] ${directionData.email !== null && directionData.email !== '' ? 'opacity-100' : 'opacity-0' }`}
            style={{fill: "#82CA9C"}} 
          />
        </div>
        <p className='w-[239px] ml-[12px] pb-0'>Ingresa tu correo electrónico.</p>
      </div>
      <div className="flex flex-row items-center w-full mb-[12px] justify-center">
        <div className="flex flex-row items-center w-[50%]">
          <FormControl className="bg-white" fullWidth>
            <TextField
              required
              id="phoneNumber"
              label="NÚMERO DE TELÉFONO:"
              variant="outlined"
              value={directionData.phoneNumber}
              onChange={(e) => handleDirectionData('phoneNumber', e)}
            />
          </FormControl>
          <CheckCircleIcon
            className={`ml-[5px] ${directionData.phoneNumber !== null && directionData.phoneNumber !== '' ? 'opacity-100' : 'opacity-0' }`}
            style={{fill: "#82CA9C"}} 
          />
        </div>
        <p className='w-[239px] ml-[12px] pb-0'>Ingresa el numero de teléfono.</p>
      </div>
      <div className="flex flex-row items-center w-full mb-[12px] justify-center">
        <div className="flex flex-row items-center w-[50%]">
          <FormControl className="bg-white" fullWidth>
            <TextField
              required
              id="postalCode"
              label="CÓDIGO POSTAL"
              variant="outlined"
              value={directionData.postalCodeId}
              disabled={directionData.state !== '' ? true : false}
              onChange={(e) => {
                handleDirectionData('postalCodeId', e)
              }}
              InputProps={{ endAdornment:
                <InputAdornment position="end">
                  {directionData.state !== ''
                    ?
                      <button
                        onClick={()=>handlePostalCodeDirectionData('clear')}
                        className="bg-blueDarken hover:bg-grayDisabled text-white w-[2.2rem] h-[2.2rem] rounded-full"
                      >
                        <EditIcon style={{fill: "#fff"}} /> 
                      </button>
                    :
                      <button
                        // disabled={fiscalId && fiscalId.length !== 13 ? true : false}
                        onClick={()=>handlePostalCodeDirectionData()}
                        className={directionData.postalCodeId.length === 5 ? `bg-blueDarken hover:bg-grayDisabled text-white w-[2.2rem] h-[2.2rem] rounded-full` : `bg-grayDisabled text-white w-[2.2rem] h-[2.2rem] rounded-full cursor-none`}
                      >
                        <SearchIcon style={{fill: "#fff"}} /> 
                      </button>
                  }
                </InputAdornment>
              }}
            />
          </FormControl>
          <CheckCircleIcon
            className={`ml-[5px] ${directionData.phoneNumber !== null && directionData.phoneNumber !== '' ? 'opacity-100' : 'opacity-0' }`}
            style={{fill: "#82CA9C"}} 
          />
        </div>
        <p className='w-[239px] ml-[12px] pb-0'>Ingresa el código postal.</p>
      </div>
      <div className="flex flex-row items-center w-full mb-[12px] justify-center">
        <div className="flex flex-row items-center w-[50%]">
          <FormControl className="bg-white" fullWidth>
            <TextField
              required
              id="company_name"
              label="Estado"
              variant="outlined"
              value={directionData.state}
              disabled={true}
            />
          </FormControl>
          <CheckCircleIcon
            className={`ml-[5px] ${directionData.state !== null && directionData.state !== '' ? 'opacity-100' : 'opacity-0' }`}
            style={{fill: "#82CA9C"}} 
          />
        </div>
        <p className='w-[239px] ml-[12px] pb-0'></p>
      </div>
      <div className="flex flex-row items-center w-full mb-[12px] justify-center">
        <div className="flex flex-row items-center w-[50%]">
          <FormControl className="bg-white" fullWidth>
            <TextField
              required
              id="company_name"
              label="ALCALDÍA/MUNICIPIO:"
              variant="outlined"
              value={directionData.locality}
              disabled={true}
            />
          </FormControl>
          <CheckCircleIcon 
            className={`ml-[5px] ${directionData.state !== null && directionData.state !== '' ? 'opacity-100' : 'opacity-0' }`}
            style={{fill: "#82CA9C"}} 
          />
        </div>
        <p className='w-[239px] ml-[12px] pb-0'></p>
      </div>
      <div className="flex flex-row items-center w-full mb-[12px] justify-center">
        <div className="flex flex-row items-center w-[50%]">
          <FormControl className='bg-white' fullWidth>
            <InputLabel id="demo-simple-select-label" className="" >Selecciona tu Colonia</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={directionData.neighborhood}
              onChange={(e) => handleDirectionData('neighborhood', e)}
            >
              {postalCodes.map((option, index) => {
                return (
                  <MenuItem key={index} value={option}>
                    {option.neighborhood}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <CheckCircleIcon 
            className={`ml-[5px] ${directionData.neighborhood !== null && directionData.neighborhood !== '' ? 'opacity-100' : 'opacity-0' }`}
            style={{fill: "#82CA9C"}} />
        </div>
        <p className='w-[239px] ml-[12px] pb-0'>Ingresa el código postal.</p>
      </div>
      <div className="flex flex-row items-center w-full mb-[12px] justify-center">
        <div className="flex flex-row items-center w-[50%]">
          <FormControl className="bg-white" fullWidth>
            <TextField
              required
              id="company_name"
              label="CALLE:"
              variant="outlined"
              value={directionData.street}
              onChange={(e) => handleDirectionData('street', e)}
            />
          </FormControl>
          <CheckCircleIcon 
            className={`ml-[5px] ${directionData.street !== '' ? 'opacity-100' : 'opacity-0' }`}
            style={{fill: "#82CA9C"}} />
        </div>
        <p className='w-[239px] ml-[12px] pb-0'>Ingresa la calle.</p>
      </div>
      <div className="flex flex-row items-center w-full mb-[12px] justify-center">
        <div className="flex flex-row items-center w-[50%]">
          <FormControl className="bg-white mr-[5px!important]" fullWidth>
            <TextField
              required
              id="company_name"
              label="NÚMERO EXTERIOR:"
              variant="outlined"
              value={directionData.internalNumber}
              onChange={(e) => handleDirectionData('internalNumber', e)}
            />
          </FormControl>
          <FormControl className="bg-white ml-[5px!important]" fullWidth>
            <TextField
              required
              id="company_name"
              label="NÚMERO INTERIOR:"
              variant="outlined"
              value={directionData.number}
              onChange={(e) => handleDirectionData('number', e)}
            />
          </FormControl>
          <CheckCircleIcon 
            className={`ml-[5px] ${directionData.internalNumber !== '' && directionData.number !== '' ? 'opacity-100' : 'opacity-0' }`}
            style={{fill: "#82CA9C"}} />
        </div>
        <p className='w-[239px] ml-[12px] pb-0'>Ingresa el número del domicilio.</p>
      </div>
      <div className="flex flex-row items-center w-full mb-[12px] justify-center">
        <div className="flex flex-row items-center w-[50%]">
          <FormControl className="bg-white" fullWidth>
            <TextField
              required
              id="company_name"
              label="REFERENCIAS:"
              variant="outlined"
              value={directionData.reference}
              onChange={(e) => handleDirectionData('reference', e)}
            />
          </FormControl>
          <CheckCircleIcon 
            className={`ml-[5px] ${directionData.reference !== '' ? 'opacity-100' : 'opacity-0' }`}
            style={{fill: "#82CA9C"}} />
        </div>
        <p className='w-[239px] ml-[12px] pb-0'></p>
      </div>
      <div className='flex mx-auto'>
        <button 
          onClick={()=> handleForm(false, null, null, null, null)}
          className='mx-[5px] border-[1px] border-blueDark hover:border-blueDarken-600 text-blueDarken bg-white disabled:border-grayDisabled disabled:text-textgrayDisabled font-medium text-white px-[1rem] py-[0.375rem] h-fit rounded-[4px] items-center uppercase text-[0.875rem] leading-[1.75] tracking-[0.02857em] space-x-3 mx-[10px]'>Cancelar</button>
        <button onClick={()=> handleDirectionForm(type)} className="bg-blueDarken disabled:bg-grayDisabled disabled:text-textgrayDisabled font-medium text-white inline-flex px-[1rem] py-[0.375rem] h-fit rounded-[4px] items-center uppercase text-[0.875rem] leading-6 tracking-[0.02857em] space-x-3 mx-[10px]">Enviar Datos</button>
      </div>
     
    </div>
    
  )
}
export default FormDirection
