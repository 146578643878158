import React from 'react'
import logoASC from '../assets/logo.svg'
import logoASCW from '../assets/logo.svg'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import YouTubeIcon from '@mui/icons-material/YouTube';
const Footer = () => {
  return (
    <footer className="pt-[3rem] text-[white!important] bg-[#002363]">
      <div className="container text-[white!important]">
        <div className="grid grid-cols sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 text-center">
          <div className="flex flex-col justify-center  text-left">
            <section className="w-100">
              <a className='text-[0.91rem] text-[white] flex items-center mb-[0.3rem] hover:text-[#00BED6] transition duration-150 ease-out hover:ease-in' href="index.php">
                <img src={logoASCW} alt='' className="w-[188px] mt-[1rem] mb-[2rem] mx-auto sm:mx-[0px]" />
              </a>
            </section>
            <ul className="list-inline text-[white] text-letft">
              <li>
                <a className='text-[0.91rem] text-[white] flex items-center mb-[0.3rem] hover:text-[#00BED6] transition duration-150 ease-out hover:ease-in w-fit' target="_blank" href="index.php">Inicio</a>
              </li>
              <li>
                <a className='text-[0.91rem] text-[white] flex items-center mb-[0.3rem] hover:text-[#00BED6] transition duration-150 ease-out hover:ease-in w-fit' target="_blank" href="quienes-somos.php">¿Quiénes somos?</a>
              </li>
            </ul>
            <h3 className="mb-[10px], text-[#6491ff] text-[1.2rem]">Programas Educativos</h3>
            <ul className="list-inline">
              <li>
                <a className='text-[0.91rem] text-[white] flex items-center mb-[0.3rem] hover:text-[#00BED6] transition duration-150 ease-out hover:ease-in w-fit' target="_blank" href="preescolar.php">Preescolar</a>
              </li>
              <li>
                <a className='text-[0.91rem] text-[white] flex items-center mb-[0.3rem] hover:text-[#00BED6] transition duration-150 ease-out hover:ease-in w-fit' target="_blank" href="primaria.php">Primaria</a>
              </li>
              <li>
                <a className='text-[0.91rem] text-[white] flex items-center mb-[0.3rem] hover:text-[#00BED6] transition duration-150 ease-out hover:ease-in w-fit' target="_blank" href="secundaria.php">Secundaria</a>
              </li>
            </ul>
          </div>
          <div className="flex flex-col md:justify-end lg:justify-start text-left">
            <h3 className="mb-[10px], text-[#6491ff] text-[1.2rem]">Pagos y facturación</h3>
            <ul className="list-inline">
              <li>
                <a className='text-[0.91rem] text-[white]' target="_blank" href="https://pagos.asc.education" rel="noreferrer">Portal de Pagos</a>
              </li>
              <li>
                <a className='text-[0.91rem] text-[white]' target="_blank" href="https://facturacion.ascc.me/" rel="noreferrer">Facturación Electrónica</a>
              </li>
            </ul>
            <h3 className="mb-[10px], text-[#6491ff] text-[1.2rem]">Herramientas ASC</h3>
            <ul className="list-inline">
              <li>
                <a className='text-[0.91rem] text-[white]' target="_blank" href="https://app.asc.education" rel="noreferrer">Aplicación de contenidos ASC</a>
              </li>
              <li>
                <a className='text-[0.91rem] text-[white]' target="_blank" href="https://colegios.asc.education/" rel="noreferrer">Portal de Directivos</a>
              </li>
              <li>
                <a className='text-[0.91rem] text-[white]' target="_blank" href="https://preescolar.asc.education" rel="noreferrer">Herramientas Preescolar</a>
              </li>
            </ul>
          </div>
          <div className="flex flex-col md:justify-end lg:justify-start text-left">
            <h3 className="mb-[10px], text-[#6491ff] text-[1.2rem]">Atención al Cliente</h3>
            <ul className="list-inline ">
              <li>
                <a className='text-[0.91rem] text-[white] flex items-center mb-[0.3rem] hover:text-[#00BED6] transition duration-150 ease-out hover:ease-in w-fit' target="_blank" href="tel:+8008417742" rel="noreferrer">+52 800 841 7742</a>
              </li>
              <li>
                <a className='text-[0.91rem] text-[white] flex items-center mb-[0.3rem] hover:text-[#00BED6] transition duration-150 ease-out hover:ease-in w-fit' target="_blank" href="mailto:info@asc.education" rel="noreferrer">info@asc.education</a>
              </li>
              <li>
                <a className='text-[0.91rem] text-[white] flex items-center mb-[0.3rem] hover:text-[#00BED6] transition duration-150 ease-out hover:ease-in w-fit' target="_blank" href="https://api.whatsapp.com/send?phone=529983465779" rel="noreferrer">Whatsapp: +52 998 346 5779</a>
              </li>
              <li>
                <a className='text-[0.91rem] text-[white] flex items-center mb-[0.3rem] hover:text-[#00BED6] transition duration-150 ease-out hover:ease-in w-fit' target="_blank" href="https://www.google.com/maps/place/ASC/@21.126099,-86.8350007,17z/data=!3m1!4b1!4m5!3m4!1s0x8f4c2bc53acdd00f:0x8623530ef38c3ff8!8m2!3d21.126099!4d-86.832812" rel="noreferrer">¿Dónde estamos?</a>
              </li>
            </ul>
            <h3 className="mb-[10px], text-[#6491ff] text-[1.2rem]">Soporte Técnico</h3>
            <ul className="list-inline">
              <li>
                <a className='text-[0.91rem] text-[white] flex items-center mb-[0.3rem] hover:text-[#00BED6] transition duration-150 ease-out hover:ease-in w-fit' target="_blank" href="mailto:soporte.tech@asc.education" rel="noreferrer">soporte.tech@asc.education</a>
              </li>
              <li>
                <a className='text-[0.91rem] text-[white] flex items-center mb-[0.3rem] hover:text-[#00BED6] transition duration-150 ease-out hover:ease-in w-fit' target="_blank" href="https://api.whatsapp.com/send?phone=529982832789" rel="noreferrer">Whatsapp: +52 998 283 2789</a>
              </li>
            </ul>
          </div>
          <div className="flex flex-col md:justify-end lg:justify-start text-left">
            <h3 className="mb-[10px], text-[#6491ff] text-[1.2rem]">Síguenos</h3>
            <a className='text-[0.91rem] text-[white] flex items-center mb-[0.3rem] hover:text-[#00BED6] transition duration-150 ease-out hover:ease-in w-fit' href="https://www.facebook.com/ASCeducation.mx" target="_blank" rel="noreferrer">
              <FacebookIcon className='mb-[0px] pb-[0px] mr-[2px]' sx={{ fontSize: '1.3rem', fill: '#6491ff' }} />
              Facebook
            </a>
            <a className='text-[0.91rem] text-[white] flex items-center mb-[0.3rem] hover:text-[#00BED6] transition duration-150 ease-out hover:ease-in w-fit' href="https://www.youtube.com/c/ASCEducaci%C3%B3nquetransforma/" target="_blank" rel="noreferrer">
              <YouTubeIcon className='mb-[0px] pb-[0px] mr-[2px]' sx={{  fontSize: '1.3rem', fill: '#6491ff' }} />
              Youtube
            </a>
            <a className='text-[0.91rem] text-[white] flex items-center mb-[0.3rem] hover:text-[#00BED6] transition duration-150 ease-out hover:ease-in w-fit' href="https://instagram.com/asc.education" target="_blank" rel="noreferrer">
              <InstagramIcon className='mb-[0px] pb-[0px] mr-[2px]' sx={{  fontSize: '1.3rem', fill: '#6491ff' }} />
              Instagram
            </a>
            <a className='text-[0.91rem] text-[white] flex items-center mb-[0.3rem] hover:text-[#00BED6] transition duration-150 ease-out hover:ease-in w-fit' href="https://www.linkedin.com/company/educacionquetransforma/" target="_blank" rel="noreferrer">
              <LinkedInIcon className='mb-[0px] pb-[0px] mr-[2px]' sx={{  fontSize: '1.3rem', fill: '#6491ff' }} />
              Linkedin
            </a>
            <a className='text-[0.91rem] text-[white] flex items-center mb-[0.3rem] hover:text-[#00BED6] transition duration-150 ease-out hover:ease-in w-fit' href="http://blog.ascc.me" target="_blank" rel="noreferrer">
              <RssFeedIcon className='mb-[0px] pb-[0px] mr-[2px]' sx={{  fontSize: '1.3rem', fill: '#6491ff' }} />
              Blog ASC
            </a>
            <a className='text-[0.91rem] text-[white] flex items-center mb-[0.3rem] hover:text-[#00BED6] transition duration-150 ease-out hover:ease-in w-fit' href="https://api.whatsapp.com/send?phone=529983465779" target="_blank" rel="noreferrer" >
              <LocalPhoneIcon className='mb-[0px] pb-[0px] mr-[2px]' sx={{  fontSize: '1.3rem', fill: '#6491ff' }} />
              Ventas: (+52) 998 346 57 79
            </a>
            <a className='text-[0.91rem] text-[white] flex items-center mb-[0.3rem] hover:text-[#00BED6] transition duration-150 ease-out hover:ease-in w-fit' href="/privacidad.html" target="_self">Política de Privacidad</a>
          </div>
            
        </div>
      </div>
      <section className="py-[1.2rem]" style={{backgroundColor: '#001742'}}>
          <h4 className="w-full text-white text-[0.8rem] text-center">
            © 2021 Editorial Morsan Internacional		
          </h4>
        </section>
    </footer>
  )
}
export default Footer
