import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getProducts, setStoreCart, setTitle, getCategories } from '../store/shopReducer';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from "react-router-dom";
const useShop = () => {
  const navigate = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [cart, setCart] = useState({ items: [],subTotal: 0,totalPrice: 0 })
  const [counter, setCounter] = useState(0)
  const { products, categories } = useSelector(({ shopReducer }) => shopReducer);
  const init = async(title) => {
    dispatch(setTitle(title));
    let isOk;
    isOk = await dispatch(getCategories());
    isOk = await dispatch(getProducts());
    if(!isOk) { addToast('Ocurrio un error: Al cargar los productos', { appearance: 'error', autoDismiss: true, }) }
  }
  const matchStringFromQuery = (query, string) => {
    const queryWords = query.toLowerCase().split(' ');
    string = string.toLowerCase();
    string = this.removeAccentsFromString(string);
    let match = true;
    queryWords.forEach((word) => {
      word = this.removeAccentsFromString(word);
      if (match && string.indexOf(word) === -1) match = false;
    });
    return match;
  }
  const productsToRender = () => {
    if (!this.products) return [];
    let filteredItems = this.products.filter((row) => {
      if (this.textFilterQuery) {
        return matchStringFromQuery(
          this.textFilterQuery, JSON.stringify(row),
        );
      }
      return true;
    });

    return filteredItems;
  }
  const discountedProducts = () => {
    if (!this.products) return null;
    return this.products.filter((product) => product.price.discountPercentage > 0);
  }
  const formatCurrencyF = (amount, currencyCode = '', isPrice = true) => {
    amount = amount.toString().replace(/\$|,/g, '');
    if (currencyCode === undefined) currencyCode = '';
    else currencyCode = ` ${currencyCode}`;
    if (Number.isNaN(amount)) amount = '0';
    let sign = '';
    const simbol = isPrice ? '$' : '';
    if (amount < 0) sign = '-';
    amount = Math.floor(amount * 100 + 0.501);
    let cents = `${amount % 100}`;
    amount = Math.floor(amount / 100).toString();
    if (cents < '10') cents = `0${cents}`;
    for (let i = 0; i < Math.floor((amount.length - (1 + i)) / 3); i += 1) {
      amount = `${amount.substring(
        0, amount.length - (4 * i + 3),
      )},${amount.substring(
        amount.length - (4 * i + 3),
      )}`;
    }
    return `${sign}${simbol}${amount}.${cents}${currencyCode}`;
  }
  const toggleProductsDelete = (product) => {
    let itms = cart.items;
    setCounter(counter-1)
    if(cart.items.filter(item => item.id === product.id)[0].cartQuantity === 1 ){
      let objIndex = cart.items.findIndex(item => item.id === product.id) 
      itms.splice(objIndex, 1)
      console.log(itms)
    }
    else {
      let objIndex = cart.items.findIndex(item => item.id === product.id) 
      itms[objIndex].cartQuantity = itms[objIndex].cartQuantity-1
    }
    setCart({...cart, 
      items: itms,
      subTotal: parseInt(cart.subTotal) - parseInt(product.price.amount),
      totalPrice: parseInt(cart.totalPrice) - parseInt(product.price.amount),
    })
  }
  const toggleProductsAdd = (product) => {
    let itms = cart.items;
    setCounter(counter+1)
    if(cart.items.filter(item => item.id === product.id).length === 0) {
      let item = JSON.parse(JSON.stringify(product));
      item.cartQuantity = 1;
      Object.preventExtensions(item);
      itms.push(item)
    }
    else {
      let objIndex = cart.items.findIndex(item => item.id === product.id) 
      itms[objIndex].cartQuantity = itms[objIndex].cartQuantity+1
    }
    setCart({...cart, 
      items: itms,
      subTotal: parseInt(cart.subTotal) + product.price.amount,
      totalPrice: parseInt(cart.subTotal) + product.price.amount,
    })
  }
  const goToPay = () => {
    localStorage.removeItem('payingCart');
    dispatch(setStoreCart(cart.items))
    navigate.push("/pay")
  }
  const formatCurrency = (price, currencyCode) => {
    if (!price) return 0;
    return formatCurrencyF(price, currencyCode);
  }
  
  return {init, discountedProducts, formatCurrency, products, categories, productsToRender, goToPay, cart, counter, toggleProductsDelete, toggleProductsAdd }
}

export default useShop
