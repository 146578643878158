import React from "react";
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import PaypalCheckoutButton from "./PaypalCheckoutButton.js";
import { Link } from 'react-router-dom'
const PaymentOptions = ({setIsAgree, currencyCode, goToDepositFormat, formatCurrency, handlePaymentOptions, creditPaymentOptions, totalAmount, isAgree, handleForm, goToCardPayment, directionData, cuponActive}) => {
  const product = {
    // description: "Design+Code React Hooks Course",
    price: totalAmount
  };
  return (
    <>
      <div className="row" id="payment">
        <div className="col-lg">
          <h5 className="text-[1.2rem] font-bold mb-[1.2rem]">
            <i className="far fa-credit-card"></i>
            Selecciona la forma de pago
          </h5>
        </div>
      </div>
      <div className="bg-blueNotice px-2 py-1 mb-[20px] rounded-[14px] px-[16px] pt-[8px] pb-[8px]">
        <p className='p-0'>
          Acepta los <Link className="underline decoration-2" to="/termns-conditions">términos y condiciones</Link> para poder elegir la <strong> forma de pago</strong> de tu preferencia.
        </p>
      </div>
      <FormControlLabel className="w-full" control={<Checkbox checked={isAgree === true ? true : false} onClick={()=> setIsAgree(!isAgree)} />} label="He leído y acepto los" />
      {false &&<div className={`bg-blueNotice px-2 py-1 mb-[20px] ${isAgree !== true ? `opacity-[0.65] text-[#f5f5f5]`: null}`}>
        <p className='p-0'>
        Sistema en mantenimiento, <strong>pago con tarjeta temportalmente deshabilitado</strong>, disculpe las molestias.
        </p>
      </div>}
      <div className={`bg-white shadow-xl rounded-[14px] px-[16px] pt-[8px] pb-[8px] text-blackASC w-fullitems-center flex py-[20px] my-[20px] flex flex-col justify-between ${isAgree !== true ? `opacity-[0.65] text-[#f5f5f5]`: null}`}>
        <div className="card-header px-4">
          <h6 className="font-semibold text-[1.1rem]">
            {
              currencyCode === 'MXN' 
              ?
                'Pagar con Visa, Mastercard o American Express'
              : 
                'Pagar con Visa o Mastercard'
            }
          </h6>
        </div>
        {/* <hr /> */}
        <div className="flex flex-row flex-wrap mt-[0.5rem]">
          <div className="flex flex-row w-full sm:w-1/2 md:w-1/3">
            {currencyCode === 'MXN' ? 
              <img
                src="/img/payment/visa-master2.png"
                alt="bbva_logo" width="80" className="h-fit"
              />
              :
              <img
                src="/img/payment/visa-master.png"
                alt="bbva_logo" width="80" className="h-fit"
              />
            }
            <p className="text-small ml-2 p-0 ml-[0.5rem]" >
              Todas las tarjetas en una sola exhibición. Excepto tarjetas <i>Visa electron</i>.
            </p>
          </div>
          <div className=" flex items-center justify-end md:justify-center w-full sm:w-1/2 md:w-1/3">
            <p className="mt-2 ml-2">
            {cuponActive && directionData.cupons == 'ASC01' && <strong>{formatCurrency((totalAmount - (totalAmount*0.10) ), currencyCode)}</strong>}
            {cuponActive && directionData.cupons == 'ASC02' && <strong>{formatCurrency((totalAmount - (totalAmount*0.15)), currencyCode)}</strong>}
            {!cuponActive && <strong>{formatCurrency(totalAmount, currencyCode)}</strong>}
            </p>
          </div>
          <div className="w-full md:w-1/3 flex items-center justify-end">
            <button type="primary" 
            className="ml-4 w-100 ml-4 w-100 bg-blueDarken disabled:bg-grayDisabled disabled:text-textgrayDisabled font-medium text-white inline-flex px-[1rem] py-[0.375rem] h-fit rounded-[4px] items-center uppercase text-[0.875rem] leading-6 tracking-[0.02857em] space-x-3" 
            onClick={() => goToCardPayment()}
            disabled={isAgree !== true ? true : false}>
              Pagar
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
export default PaymentOptions
