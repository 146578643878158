import React from 'react'
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import FolderIcon from '@mui/icons-material/Folder';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PaymentsIcon from '@mui/icons-material/Payments';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Link } from 'react-router-dom'
// const steps = ['Carrito', 'Dirección de envio', 'Datos de facturación', 'Forma de pago', 'Pago'];
const steps = ['Dirección de envio', 'Forma de pago', 'Pago'];
const StatusBar = () => {
  const step = parseInt(useSelector(({ shopReducer }) => shopReducer.step));
  return ( 
    <Stepper activeStep={step} alternativeLabel className='my-[1.2rem]'>
        {steps.map((label) => (
          <Step key={label} className="fill-[#256e9d]">
            <StepLabel 
              // sx={{.Mui-completed fill: '#256e9d',}} 
              sx={{ '& .Mui-completed': { fill: '#256e9d'}}}
              className="fill-[#256e9d] text-[#256e9d]"
            >
              {label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    // <div className='hidden md:block mb-[20px]'>
    //   <div className="grid grid-cols-5">
    //     <div className="flex flex-col items-center">
    //       <Link to="/shop">
    //         <Avatar sx={{ bgcolor: '#256e9d'}}>
    //           <ShoppingCartIcon style={{ fill: '#fff' }} />
    //         </Avatar>
    //         <p>Carrito</p>
    //       </Link>
    //     </div>
    //     <div className="flex flex-col items-center">
    //       <Avatar sx={{ bgcolor: step >= 2  ? '#256e9d' : '' }}>
    //         <FolderIcon style={{ fill: '#fff' }} />
    //       </Avatar>
    //       <p>Datos de facturacion</p>
    //     </div>
    //     <div className="flex flex-col items-center">
    //       <Avatar sx={{ bgcolor: step >= 3  ? '#256e9d' : '' }}>
    //         <LocalShippingIcon style={{ fill: '#fff' }} />
    //       </Avatar>
    //       <p>Dirección de envio</p>
    //     </div>
    //     <div className="flex flex-col items-center">
    //       <Avatar sx={{ bgcolor: step >= 4 ? '#256e9d' : '' }}>
    //         <PointOfSaleIcon style={{ fill: '#fff' }} />
    //       </Avatar>
    //       <p>Forma de pago</p>
    //     </div>
    //     <div className="flex flex-col items-center">
    //       <Avatar sx={{ bgcolor: step >= 5 ? '#256e9d' : '' }}>
    //         <PaymentsIcon style={{ fill: '#fff' }} />
            
    //       </Avatar>
    //       <p>Pago</p>
    //     </div>
    //   </div>
    //   <BorderLinearProgress variant="determinate" value={20 * step} />
    // </div>
  )
}
export default StatusBar