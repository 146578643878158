import React from 'react';
import Footer from '../components/FooterExtra'
import Header from '../components/HeaderExtra'
const TermsConditions = () => {
  return (
    <>
      <Header />
      <div class="container my-[3.5rem]">
        <h2 class="text-[1.2rem] font-bold">TERMINOS Y CONDICIONES DE USO</h2>
        <ol class="ml-[1rem]">
          <li><p>1. Una vez realizado el envío de los libros no se aceptará la devolución de los mismos, excepto si el material recibido no corresponde con el solicitado en su pedido.</p></li>
          <li><p>2. Las cancelaciones se penalizan con el 5% del monto total hasta el 15 de agosto.</p></li>
          <li><p>3. En caso de que el cliente no reconozca el cargo aplicado a su tarjeta por parte de Editorial Morsan se aplicará el precio vigente al momento de realizar el pago correspondiente.</p></li>
          <li><p>4. Para poder tramitar la devolución de su compra, deberá ser solicitada por escrito vía correo electrónico al mail: info@asc.education.</p></li>
          <li>
            <p>5. Las políticas para reembolsos serán la siguientes:</p>
            <ul>
              <li>
                <p>
                  <span class="font-medium">Preescolar, Primaria y secundaria</span>Solicitudes a partir del 16 de agosto y hasta el 31 de diciembre del presente año se reembolsará el 50%, a partir del 1 de enero ya no aplican
                  devoluciones.
                </p>
              </li>
            </ul>
          </li>
        </ol>
        <h2 class="text-[1.2rem] font-bold">FACTURACIÓN</h2>
        <p>
          Para solicitar factura deberá haber efectuado el pago (pago en línea o depósito bancario) y que en el sistema de pagos de ASC, aparezca la compra con estatus "pagado". Los pagos hechos en efectivo directo en el banco se verán
          reflejados en el sistema ASC dos días hábiles después.
        </p>
        <p>Los requisitos fiscales que debe proporcionar para emitir la factura son los siguientes de acuerdo a su Constancia del RFC:</p>
        <ol class="ml-[1rem]">
          <li class="pb-[0px]">
            <p class="pb-[0px]">a. <span class="font-bold">Nombre o Razón Social.</span></p>
          </li>
          <li class="pb-[0px]">
            <p class="pb-[0px]">b. <span class="font-bold">Registro Federal de Contribuyentes.</span></p>
          </li>
          <li class="pb-[0px]">
            <p class="pb-[0px]">c. <span class="font-bold">Domicilio fiscal (calle, número exterior e interior, estado, ciudad, delegación, código postal)</span></p>
          </li>
          <li class="pb-[0px]">
            <p class="pb-[0px]">d. <span class="font-bold">Correo electrónico.</span></p>
          </li>
        </ol>
        <p>En caso de no contar con el RFC expedida por el Servicio de Administración Tributaria (SAT), se le entregará un comprobante fiscal simplificado, del cual no podrá acreditarse o deducir cantidad alguna.</p>
        <p>Será responsabilidad del interesado que la información que se haya capturado en la solicitud sea correcta, ya que una vez expedida la factura esta no podrá ser modificada o refacturada.</p>
        <p>Dicha factura deberá solicitarse dentro del mes en el cual se haya efectuado el pago. Por ningún motivo se expedirán facturas extemporáneas.</p>
        <p>La factura contendrá la fecha en la que fue generada sin excepción alguna. Siempre y cuando, cumpla con el requisito de ser solicitada dentro del mes calendario que se realizó la compra.</p>
        <p>El archivo XML será enviado a través de una liga de manera automática a la dirección de correo electrónico que proporcione al generar la factura, sugerimos verifique dicha dirección ya que el archivo XML no será recuperable.</p>
      </div>

      <Footer />
    </>
  )
}
export default TermsConditions
