import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_CONFIG } from '../serverConfig'
// const token = localStorage.getItem('access_token')
// export const saveMail = (nameStudent,schoolSelected,academicYearPaying) => async (dispatch, getState) => {
// 	try {
// 		const response =  await axios.put(`${API_CONFIG.API_URL}persons/${this.registerFormPerson.id}/email`, { headers: {'x-app-id': `${API_CONFIG.APP_ID}`, 'Authorization': `Bearer ${token}`}})
// 	}
// 	catch (error) {

// 	}
// }
export const paypalLink = (body) => async (dispatch, getState) => {
	try {
		const t = getState().authReducer.token
		const token = t !== null ? t :  localStorage.getItem('access_token');
		let response = await axios.post(`${API_CONFIG.API_URL}payments/paypal-v1	`, body, { headers: { 'x-app-id': `${API_CONFIG.APP_ID}`, 'Authorization': `Bearer ${token}`}} )
		dispatch(setPayReference(response.data.URL))	
		console.log(response.data.URL)
		return response.data.URL
	}
	catch (error) {
		console.log('paypalLink', error)
		return false
	}
}
export const completepaypalLink = (body) => async (dispatch, getState) => {
	try {
		const t = getState().authReducer.token
		const token = t !== null ? t :  localStorage.getItem('access_token');
		let response = await axios.post(`${API_CONFIG.API_URL}payments/paypal-v1-notification?reference=${body}`, { headers: { 'x-app-id': `${API_CONFIG.APP_ID}`, 'Authorization': `Bearer ${token}`}} )
		await axios.post(`${API_CONFIG.API_URL}payments/webpay-response/${body}`, { headers: { 'x-app-id': `${API_CONFIG.APP_ID}`, 'Authorization': `Bearer ${token}`}} )
		dispatch(setPayReference(response.data.URL))	
		return true
	}
	catch (error) {
		console.log('completepaypalLink', error)
		return false
	}
}
export const saveAddStudents = (body, usuario) => async (dispatch, getState) => {
	try {
		const t = getState().authReducer.token
		const token = t !== null ? t :  localStorage.getItem('access_token');
		await axios.post(`${API_CONFIG.API_URL}persons/${usuario.person.id}/add-relation`, body, { headers: { 'x-app-id': `${API_CONFIG.APP_ID}`, 'Authorization': `Bearer ${token}`}} )
		return true
	}
	catch (error) {
		console.log('saveAddStudents', error)
		return false
	}
}
export const deletStudent = (body, usuario) => async (dispatch, getState) => {
	try {
		const t = getState().authReducer.token
		const token = t !== null ? t :  localStorage.getItem('access_token');
		await axios.delete(`${API_CONFIG.API_URL}persons/${usuario.person.id}/remove-relation`, { data: body,  headers: { 'x-app-id': `${API_CONFIG.APP_ID}`, 'Authorization': `Bearer ${token}`}} )
		return true
	}
	catch (error) {
		console.log('deletStudent', error)
		return false
	}
}
export const getStudents = (nameStudent,schoolSelected,academicYearPaying) => async (dispatch, getState) => {
	try {
		const t = getState().authReducer.token
		const token = t !== null ? t :  localStorage.getItem('access_token');
		let response = await axios.get(`${API_CONFIG.API_URL}schools/${schoolSelected}/search-students?q=${nameStudent.name} ${nameStudent.lastNameP} ${nameStudent.lastNameM}&academicYearId=${academicYearPaying}`, { headers: {'x-app-id': `${API_CONFIG.APP_ID}`, 'Authorization': `Bearer ${token}`}})
		dispatch(setStudents(response.data))	
		return true;
	}
	catch (error) {
		console.log('getStudents', error)
		return false
	}
}
export const getRelations = () => async (dispatch, getState) => {
	try {
		const t = getState().authReducer.token
		const token = t !== null ? t :  localStorage.getItem('access_token');
		let response = await axios.get(`${API_CONFIG.API_URL}dropdowns/persons/relations`, { headers: {'x-app-id': `${API_CONFIG.APP_ID}`, 'Authorization': `Bearer ${token}`}})
		dispatch(setRelations(response.data))	
		return true;
	}
	catch (error) {
		console.log('getRelations', error)
		return false
	}
}
export const getCountyes = (selectedState) => async (dispatch, getState) => {
	try {
		const t = getState().authReducer.token
		const token = t !== null ? t :  localStorage.getItem('access_token');
		const response =  await axios.get(`${API_CONFIG.API_URL}dropdowns/postal-codes/${selectedState}/school-counties`, { headers: {'x-app-id': `${API_CONFIG.APP_ID}`, 'Authorization': `Bearer ${token}`}})
		dispatch(setCountyData(response.data))
		return true
	}
	catch (error) {
		console.log('getCountyes', error)
		return false
	}
}
export const getStates = (currentUserCountryCode) => async (dispatch, getState) => {
	try {
		const t = getState().authReducer.token
		const token = t !== null ? t :  localStorage.getItem('access_token');
		const response =  await axios.get(`${API_CONFIG.API_URL}dropdowns/postal-codes/${currentUserCountryCode}/states`, { headers: {'x-app-id': `${API_CONFIG.APP_ID}`, 'Authorization': `Bearer ${token}`}})
		dispatch(setStateData(response.data))
		return true
	}
	catch (error) {
		console.log('getStates', error)
		return error
	}
}
export const getAcademicYearsPaying = () => async (dispatch, getState) => {
	try {
		const t = getState().authReducer.token
		const token = t !== null ? t :  localStorage.getItem('access_token');
		const response = await axios.get(`${API_CONFIG.API_URL}countries/MX/academic-years-paying`, { headers: { 'x-app-id': `${API_CONFIG.APP_ID}`, 'Authorization': `Bearer ${token}`}})
		dispatch(setAcademicYears(response.data))
		dispatch(loginSuccess())
		return true
	}
	catch (error) {
		console.log('getAcademicYearsPaying', error)
		return false
	}
}

export const getStudent = (person) => async (dispatch, getState) => {
	try {
		const t = getState().authReducer.token
		const token = t !== null ? t :  localStorage.getItem('access_token');
		let response = await axios.get(`${API_CONFIG.API_URL}persons/${person.id}/student-list`, { headers: { 'x-app-id': `${API_CONFIG.APP_ID}`, 'Authorization': `Bearer ${token}`}})
		dispatch(setStudentList(response.data))
		return response.data
	}
	catch (error) {
		console.log('getStudent', error)
		dispatch(setStudentList([]))
		return error
	}
}
export const getSchools = (schoolName, selectedCounty) => async (dispatch, getState) => {
	try {
		let response = await axios.get(`${API_CONFIG.API_URL}schools/search/county-name?q=${schoolName}&countyId=${selectedCounty}`)
		dispatch(setSchools(response.data))
		return true
	}
	catch (error) {
		console.log('getSchools', error)
		dispatch(setSchools([]))
		return false
	}
}



const paymentsAdapter = createEntityAdapter({});

export const { selectAll: selectEvents, selectById: selectEventsById } = paymentsAdapter.getSelectors(
	state => state.App.payment
);

const paymentSlice = createSlice({
	name: 'App/payment',
	initialState:paymentsAdapter.getInitialState({
		payByScholl: [],
		academicYears: [],
		students: null,
		studentsList: [],
		states: [],
		county: [],
		schools: null,
		title: null,
		relations: [],
		payReference: 5
	}),
	reducers: {
		setPayReference: (state, action) => {
			state.payReference = action.payload;
		},
		setRelations: (state, action) => {
			state.relations = action.payload;
		},
		setStateData: (state, action) => {
			state.states = action.payload;
		},
		setCountyData: (state, action) => {
			state.county = action.payload;
		},
		loginSuccess: (state, action) => {
			state.isAuthenticated = true;
			state.isLoading = false;
		},
		setAcademicYears: (state, action) => {
			state.academicYears = action.payload
		},
		setStudentList: (state, action) => {
			state.studentsList = action.payload
		},
		setStudents: (state, action) => {
			state.students = action.payload
		},
		setSchools: (state, action) => {
			state.schools = action.payload
		},
		setKrismar: (state, action) => {
			state.krismar = action.payload
		},
		setRoles: (state, action) => {
			state.roles = action.payload
		},
		authError: (state, action) => {
			state.isAuthenticated = false;
			state.isLoading = false;
		}
	},
	extraReducers: {}
});

export const { loginSuccess, authError, setKrismar, setRoles, setAcademicYears, setStudents, setStudentList, setStateData, setCountyData, setSchools, setRelations,setPayReference} = paymentSlice.actions;

export default paymentSlice.reducer;
