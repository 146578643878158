import React from "react";
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
const Invoice = ({handleInvoice,fiscalId,setFiscalId,searcFiscalID,handleForm,needInvoice,fiscalD}) => {
  return (
    <>
      <div className="row mx-2 my-[1rem]">
        <h3 className="text-[1.5rem] font-bold">
          ¿Deseas facturar?
        </h3>
      </div>
      <div className="bg-white shadow-xl rounded-[14px] px-[16px] pt-[8px] pb-[8px] text-blackASC w-fullitems-center flex py-[20px] my-[20px] flex flex-col justify-between">
        <div className="flex flex-col sm:flex-row justify-around my-[10px]">
          <FormControlLabel className="w-full justify-start sm:justify-center" control={<Checkbox checked={needInvoice === false ? true : false} onClick={()=> handleInvoice(false)} />} label="No, pagar sin facturar" />
          <FormControlLabel className="w-full justify-start sm:justify-center" control={<Checkbox  checked={needInvoice === true ? true : false} onClick={()=> handleInvoice(true)} />} label="Si, verificar datos de factura" />
          {needInvoice === true && 
            <FormControl className="bg-white" fullWidth>
              <TextField
                required
                id="postalCode"
                label="RFC"
                variant="outlined"
                value={fiscalId}
                onChange={(e) => setFiscalId(e.target.value.toUpperCase())}
                disabled={fiscalD !== null || false}
                InputProps={{ endAdornment:
                  <InputAdornment position="end">
                    {fiscalD !== null || false
                      ?
                        <button
                          onClick={()=>handleInvoice(true)}
                          className="bg-blueDarken hover:bg-grayDisabled text-white w-[2.2rem] h-[2.2rem] rounded-full"
                        >
                          <EditIcon style={{fill: "#fff"}} /> 
                        </button>
                      :
                        <button
                          disabled={fiscalId && fiscalId.length !== 13 ? true : false}
                          onClick={()=>searcFiscalID()}
                          className={fiscalId && fiscalId.length === 13 ? `bg-blueDarken hover:bg-grayDisabled text-white w-[2.2rem] h-[2.2rem] rounded-full` : `bg-grayDisabled text-white w-[2.2rem] h-[2.2rem] rounded-full cursor-none`}
                        >
                          <SearchIcon style={{fill: "#fff"}} /> 
                        </button>
                    }
                  </InputAdornment>
                }}
              />
            </FormControl>
          }
        </div>
      </div>
      {needInvoice !== null &&
        <div className="bg-blueNotice px-2 py-1 mb-[20px] rounded-[14px] px-[16px] pt-[8px] pb-[8px]">
          <p className='p-0'>
            {needInvoice && <>Estimado cliente, si no requiere <strong>factura</strong> esta será timbrada 
            <strong> automáticamente</strong> como público en general y no será posible
            realizar cambios posteriores.</>}
            {needInvoice === false && <><strong> Importante:</strong> si su pago es con tarjeta, la factura llegará en
            el transcurso de las siguientes 24 horas después de pagar.
            <br/>
            Si paga con ficha de depósito, la factura llegará en el transcurso de 24 a 48
            horas después de confirmado el depósito.</>}
          </p>
        </div>
      }
      {needInvoice === true &&
        <>
          {fiscalD && fiscalD.businessName !== null &&
            <>
              <div className="col-lg d-flex align-items-center mb-[0.6rem]">
                <p className="pb-[0px]">Estos son los datos registrados para <span className="font-bold">generar tu factura</span>.</p>
                <p>En caso de no ser los correctos, puedes <button className="underline decoration-2 font-bold ml-[0.1rem]" onClick={()=> handleForm(true, 'invoice', null, null, 2)}> editarlos</button>.</p>
              </div>
              <div className="bg-white shadow-xl rounded-[15px] px-[16px] pt-[8px] pb-[8px] text-blackASC w-fullitems-center flex py-[20px] mb-[20px] flex flex-col sm:flex-row justify-between content-end items-end">
                  <div className="flex flex-col w-full">
                    <h5 className="font-bold text-[1.1rem] mb-[0.3rem]">Datos de facturación.</h5>
                    <div className="flex">
                      <h6 className="font-bold">Razón social:</h6>
                      <p className="pb-0"> {fiscalD.businessName || 'Sin información'}</p>
                    </div>
                    <div className="flex sm:flex-nowrap">
                      <h6 className="font-bold">RFC:</h6> 
                      <p className="pb-0"> {fiscalD.fiscalId}</p>
                    </div>
                    <div className="flex sm:flex-nowrap">
                      <h6 className="font-bold">Dirección:</h6>
                      <p className="pb-0"> {(fiscalD.street || fiscalD.number) && `${fiscalD.street} ${fiscalD.number}, `} 
                      {fiscalD.neighborhood && ` ${fiscalD.neighborhood}, `}
                      {fiscalD.postalCode && ` C.P. ${fiscalD.postalCode}`}
                      {!fiscalD.postalCode && <>Sin información</>}</p>
                    </div>
                    <div className="flex sm:flex-nowrap">
                      <h6 className="font-bold">Ciudad:</h6>
                      <>{fiscalD.locality && ` ${fiscalD.locality}, `}{fiscalD.state && ` ${fiscalD.state}, `}{fiscalD.countryId && ` ${fiscalD.countryId}`}</>
                    </div>
                  </div>
                  <div className="flex sm:justify-end w-full sm:w-auto mt-[0.5rem]">
                    <button 
                      onClick={()=> handleForm(true, 'invoice',null, null, 2)} 
                      className="bg-blueDarken disabled:bg-grayDisabled disabled:text-textgrayDisabled font-medium text-white inline-flex px-[1rem] py-[0.375rem] h-fit rounded-[4px] items-center uppercase text-[0.875rem] leading-6 tracking-[0.02857em] space-x-3 sm:mx-[10px]"
                    >
                      <i className="fas fa-edit"></i>
                      Editar
                    </button>
                  </div>
              </div>
            </>
          }
          
          {fiscalD === false &&
              <>
                <div className="mb-[0.6rem]">
                  <p>
                    No hay datos registrados para <strong>generar tu factura</strong>.
                    <br />
                    Puedes 
                    <button 
                      className="underline decoration-2 font-bold ml-[0.1rem]"
                      onClick={()=> handleForm(true,'invoice', null, null, 2)}
                    >agregarlos ahora</button>.
                  </p>
                </div>
                <div className="bg-white shadow-xl rounded-[15px] px-[16px] pt-[8px] pb-[8px] text-blackASC w-fullitems-center flex py-[20px] mb-[20px] flex flex-col sm:flex-row justify-between justify-between content-end items-end">
                  <div className="flex flex-col w-full">
                    <h5 className="font-bold text-[1.1rem] mb-[0.3rem]">Datos de facturación.</h5>
                    <p className="mb-[0px] pb-[0px]">No hay datos registrados.</p>
                  </div>
                  <div className="flex sm:justify-end w-full sm:w-auto mt-[0.5rem]">
                    <button 
                      onClick={()=> handleForm(true, 'invoice',null, null, 2)}
                      className="bg-blueDarken disabled:bg-grayDisabled disabled:text-textgrayDisabled font-medium text-white inline-flex px-[1rem] py-[0.375rem] h-fit rounded-[4px] items-center uppercase text-[0.875rem] leading-6 tracking-[0.02857em] space-x-3 sm:mx-[10px]"
                    >
                      Agregar</button>
                  </div>
              </div>
                    
              </>   
          }
        </> 
      }
    </>
  )
}
export default Invoice
