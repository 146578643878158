import React from 'react';
import Header from './Header';
import Fotter from './Footer';
import BreadCrum from './BreadCrum';
import StatusBar from './StatusBar';
const Layout = ({ children, isAuthenticated, isStore }) => {
  return (
      <div className="mainLayoutContainer">
        <Header isAuthenticated={isAuthenticated}/>
        <div className='container mx-auto mt-[2.3rem] mb-[1rem]'>
          <BreadCrum />
          {children}
        </div>
        <Fotter />
      </div>
  )
}

export default Layout
