import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
const TCBanks = () => {
  return (
    <div className="bg-white shadow-xl rounded-[15px] px-[20px] pt-[18px] pb-[20px] mt-[20px] md:mt-[0px] mb-4 text-blackASC min-w-[80%] lg:h-fit w-full grid gap-[1rem] sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      <div className='sm:col-span-2 md:col-span-3 lg:col-span-4 relative flex flex-row justify-center w-full pr-[3rem] sm:px-[2rem]'>
        <h3 className='text-center'>ACTUALIZAR DATOS DE FACTURACIÓN</h3>
        <CancelIcon style={{fontSize: '2rem!important'}} className='text-2rem absolute right-[0px]'/>
      </div>
      <div className="bg-white border-[1px] rounded-[14px] px-[16px] pt-[8px] pb-[8px] text-blackASC flex py-[20px] justify-between  ">
        <div className="flex flex-col">
          <strong>American Express</strong>
          <span>3 y 6 meses</span>
        </div>
        <img src="/img/payment/bank-01.png" alt="bank_logo"  className="w-[4.6rem] h-[2.8rem]" />
      </div>

      <div className="bg-white border-[1px] rounded-[14px] px-[16px] pt-[8px] pb-[8px] text-blackASC flex py-[20px] justify-between ">
        <div className="flex flex-col">
          <strong>Banca AFIRME</strong>
          <span>3 y 6 meses</span>
        </div>
        <img src="/img/payment/bank-02.png" alt="bank_logo"  className="w-[4rem] h-[2.8rem]" />
      </div>

      <div className="bg-white border-[1px] rounded-[14px] px-[16px] pt-[8px] pb-[8px] text-blackASC flex py-[20px] justify-between ">
        <div className="flex flex-col">
          <strong>Banca Mifel</strong>
          <span>3 y 6 meses</span>
        </div>
        <img src="/img/payment/bank-03.png" alt="bank_logo"  className="w-[3.8rem] h-[2.8rem]" />
      </div>

      <div className="bg-white border-[1px] rounded-[14px] px-[16px] pt-[8px] pb-[8px] text-blackASC flex py-[20px] justify-between ">
        <div className="flex flex-col">
          <strong>Banco Ahorro</strong>
          <span>3 y 6 meses</span>
        </div>
        <img src="/img/payment/bank-04.png" alt="bank_logo"  className="w-[2.8rem] h-[3.2rem]" />
      </div>

      <div className="bg-white border-[1px] rounded-[14px] px-[16px] pt-[8px] pb-[8px] text-blackASC flex py-[20px] justify-between ">
        <div className="flex flex-col">
          <strong>Banco del Bajío</strong>
          <span>3 y 6 meses</span>
        </div>
        <img src="/img/payment/bank-05.png" alt="bank_logo"  className="w-[3.2rem] h-[3.2rem]" />
      </div>

      <div className="bg-white border-[1px] rounded-[14px] px-[16px] pt-[8px] pb-[8px] text-blackASC flex py-[20px] justify-between ">
        <div className="flex flex-col">
          <strong>Banco INBURSA</strong>
          <span>3 y 6 meses</span>
        </div>
        <img src="/img/payment/bank-06.png" alt="bank_logo"  className="w-[3.2rem] h-[3.2rem]" />
      </div>

      <div className="bg-white border-[1px] rounded-[14px] px-[16px] pt-[8px] pb-[8px] text-blackASC flex py-[20px] justify-between ">
        <div className="flex flex-col">
          <strong>Banco INVEX</strong>
          <span>3 y 6 meses</span>
        </div>
        <img src="/img/payment/bank-07.png" alt="bank_logo"  className="w-[4.8rem] h-[1.6rem]" />
      </div>
      <div className="bg-white border-[1px] rounded-[14px] px-[16px] pt-[8px] pb-[8px] text-blackASC flex py-[20px] justify-between ">
        <div className="flex flex-col">
          <strong>Banjército</strong>
          <span>3 y 6 meses</span>
        </div>
        <img src="/img/payment/bank-08.png"  alt="bank_logo" className="w-[3rem] h-[3rem]" />
      </div>

      <div className="bg-white border-[1px] rounded-[14px] px-[16px] pt-[8px] pb-[8px] text-blackASC flex py-[20px] justify-between ">
        <div className="flex flex-col">
          <strong>Banorte</strong>
          <span>3 y 6 meses</span>
        </div>
        <img src="/img/payment/bank-09.png" alt="bank_logo"  className="w-[3.2rem] h-[3.2rem]" />
      </div>

      <div className="bg-white border-[1px] rounded-[14px] px-[16px] pt-[8px] pb-[8px] text-blackASC flex py-[20px] justify-between ">
        <div className="flex flex-col">
          <strong>Banregio</strong>
          <span>3 y 6 meses</span>
        </div>
        <img src="/img/payment/bank-10.png" alt="bank_logo"  className="w-[6.3rem] h-[1.3rem]" />
      </div>

      <div className="bg-white border-[1px] rounded-[14px] px-[16px] pt-[8px] pb-[8px] text-blackASC flex py-[20px] justify-between ">
        <div className="flex flex-col">
          <strong>BBVA Bancomer</strong>
          <span>3 y 6 meses</span>
        </div>
        <img src="/img/payment/bank-11.png"  alt="bank_logo"  className="w-[5rem] h-[3rem]" />
      </div>

      <div className="bg-white border-[1px] rounded-[14px] px-[16px] pt-[8px] pb-[8px] text-blackASC flex py-[20px] justify-between ">
        <div className="flex flex-col">
          <strong>HSBC</strong>
          <span>3 y 6 meses</span>
        </div>
        <img src="/img/payment/bank-12.png" alt="bank_logo"  className="w-[3.7rem] h-[3.2rem]" />
      </div>

      <div className="bg-white border-[1px] rounded-[14px] px-[16px] pt-[8px] pb-[8px] text-blackASC flex py-[20px] justify-between ">
        <div className="flex flex-col">
          <strong>IXE Banco</strong>
          <span>3 y 6 meses</span>
        </div>
        <img src="/img/payment/bank-13.png" alt="bank_logo"  className="w-[4.6rem] h-[1.8rem]" />
      </div>

      <div className="bg-white border-[1px] rounded-[14px] px-[16px] pt-[8px] pb-[8px] text-blackASC flex py-[20px] justify-between ">
        <div className="flex flex-col">
          <strong>Banco Liverpool</strong>
          <span>3 y 6 meses</span>
        </div>
        <img src="/img/payment/bank-14.png" alt="bank_logo"  className="w-[3.2rem] h-[2.8rem]" />
      </div>

      <div className="bg-white border-[1px] rounded-[14px] px-[16px] pt-[8px] pb-[8px] text-blackASC flex py-[20px] justify-between ">
        <div className="flex flex-col">
          <strong>Banco Multiva</strong>
          <span>3 y 6 meses</span>
        </div>
        <img src="/img/payment/bank-15.png" alt="bank_logo"  className="w-[2.7rem] h-[3rem]" />
      </div>

      <div className="bg-white border-[1px] rounded-[14px] px-[16px] pt-[8px] pb-[8px] text-blackASC flex py-[20px] justify-between ">
        <div className="flex flex-col">
          <strong>Santander</strong>
          <span>3 y 6 meses</span>
        </div>
        <img src="/img/payment/bank-16.png" alt="bank_logo"  className="w-[3.3rem] h-[3rem]" />
      </div>

      <div className="bg-white border-[1px] rounded-[14px] px-[16px] pt-[8px] pb-[8px] text-blackASC flex py-[20px] justify-between ">
        <div className="flex flex-col">
          <strong>Scotiabank</strong>
          <span>3 y 6 meses</span>
        </div>
        <img src="/img/payment/bank-17.png" alt="bank_logo"  className="w-[3.1rem] h-[3.4rem]" />
      </div>

    </div>
  )
}
export default TCBanks
