import React from 'react'
import { useSelector } from 'react-redux';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom'

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
const BreadCrum = () => {
  const title = useSelector(({ shopReducer }) => shopReducer.title);
  // const { title, subtitle } = titles;
  return (
    <div className='flex flex-col'>
      <div className="row mx-2 mb-[1rem]">
        <h3 className="text-[1.6rem] font-bold leading-none"> 
        Tienda ASC
        </h3>
      </div>
      <div>
        <Breadcrumbs maxItems={2} separator={<FiberManualRecordIcon className='mx-[0.5rem]' style={{ fontSize: '0.5rem'}}/>} className='text-[0.87rem]' aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to={'/'}>
            Tienda ASC
          </Link>
          <p className='text-[#919eab] pb-0 leading-none'>
            {title}
          </p>
        </Breadcrumbs>
      </div>
    </div>
  )
}
export default BreadCrum