import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Badge from "@mui/material/Badge";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import IconButton from "@mui/material/IconButton";
import useShop from "../hooks/useShop";
import bookImg from "../assets/mindware.jpg";
import Slider from "../components/Slider";
const Shop = () => {
  const {
    init,
    discountedProducts,
    formatCurrency,
    getFinalPrice,
    products,
    categories,
    addToCart,
    goToPay,
    cart,
    counter,
    toggleProductsDelete,
    toggleProductsAdd,
  } = useShop();
  useEffect(() => {
    init("Selección de productos");
  }, []);
  return (
    <>
      <section>
        {products && products.length > 0 && (
          <div>
            {/* <Slider /> */}

            {/* {products.categoryId === 1 && */}
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-[0px] sm:gap-x-[15px] gap-y-[20px] w-full mt-[20px]'>
              <div className='relative w-full md:order-last sm:col-span-2 md:col-span-1'>
                <div className='flex bg-white shadow-xl shadow-gray-600 rounded-[15px]  text-blackASC flex-col top-0 w-full md:w-fit row-span-4 sm:col-span-2 md:col-span-1 py-[0.7rem] px-[0.8rem] md:fixed top-0'>
                  <h4 className='text-[1.25rem] font-semibold border-grayDisabled mb-[0.6rem] leading-none'>
                    Carrito
                  </h4>
                  {cart.items.length > 0 ? (
                    <>
                      <table className='px-[20px] pt-[18px] pb-[20px]'>
                        <thead>
                          <tr>
                            <th className='text-[0.7rem] truncate'>Articulo</th>
                            <th className='text-[0.7rem]'>Cantidad</th>
                            <th className='text-[0.7rem]'>Precio</th>
                          </tr>
                        </thead>
                        <tbody className='divide-y'>
                          {cart.items.map((item, indexCart) => (
                            <tr key={`c-${indexCart}`} className='slide-up-fade-in'>
                              <td className='text-[0.8rem] font-semibold truncate lowecase'>
                                {item.name.substr(0, 12)}
                                {item.name.length > 12 && "..."}
                              </td>
                              <td className='text-[0.8rem] text-center'>
                                {item.cartQuantity}
                              </td>
                              <td className='text-[0.8rem] text-center'>
                                {/* {item.price.amount} */}
                                {formatCurrency(item.price.amount, "MXN")}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className='px-8 mt-30 ml-auto text-right my-[0.6rem]'>
                        <p className='p-[0px] text-[0.8rem]'>
                          <span className='font-bold text-[0.8rem]'>
                            Subtotal
                          </span>
                          {formatCurrency(cart.subTotal, "MXN")}
                        </p>
                        <p className='p-[0px] text-[0.8rem]'>
                          <span className='font-bold text-[0.8rem]'>Total</span>
                          {formatCurrency(cart.totalPrice, "MXN")}
                        </p>
                      </div>
                      <button
                        disabled={cart.totalPrice === 0 ? true : false}
                        onClick={() => goToPay()}
                        className='bg-greenToast hover:opacity-[.7] disabled:bg-grayDisabled disabled:text-textgrayDisabled font-medium text-white px-[1rem] py-[0.375rem] h-fit rounded-[4px] items-center uppercase text-[0.875rem] leading-[1.75] tracking-[0.02857em] space-x-3 text-center'
                      >
                        Pagar
                      </button>
                    </>
                  ) : (
                    <p className='text-[0.87rem]'>
                      Aun no hay productos en tu carrito.
                    </p>
                  )}
                </div>
              </div>
              <div className='col-span-2 lg:col-span-3 '>
                {categories.map((categorie, i) => (
                  <>
                    <div className='col-span-3' key={`categoryProduct-${i}`}>
                      <h3 className='text-[1.6rem] font-bold my-[1.1rem]'>
                        {categorie.name}
                      </h3>
                    </div>
                    <div className='grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-x-[20px] gap-y-[30px]'>
                      {products.map(
                        (product, indexProduct) =>
                          product.categoryId === categorie.id &&
                          product.categoryId !== 2 && (
                            <div
                              className='bg-white shadow-xl shadow-gray-600 rounded text-blackASC flex flex-col rounded-[15px]'
                              key={`product-${indexProduct}`}
                            >
                              <div className='bg-bluePrimary bg-opacity-[.1] rounded-t-[15px]'>
                                <img
                                  src={product.image || bookImg}
                                  className='w-[35%] mx-auto py-[10px]'
                                  alt='...'
                                />
                              </div>
                              <div className='py-[0.7rem] px-[1rem] flex flex-col justify-between h-[100%]'>
                                <div>
                                  <h3 className='text-bluePrimary text-[1.3rem] leading-none mb-[0.5rem]'>
                                    {product.name}
                                  </h3>
                                  <p className='card-description text-[0.8rem] pb-0'>
                                    {product.description}
                                  </p>
                                </div>
                                {/* <button className='text-[0.8rem] pt-[0px] leading-none'>Leer mas...</button> */}
                                <div className='card-footer mt-[5px]'>
                                  <>
                                    {product.price.discountPercentage > 0 ? (
                                      <>
                                        <span className='font-bold mr-2'>
                                          {formatCurrency(
                                            product.price.amount,
                                            product.price.currencyCode
                                          )}
                                        </span>
                                        <span className='font-bold'>
                                          {formatCurrency(
                                            getFinalPrice(product.price),
                                            product.price.currencyCode
                                          )}
                                        </span>
                                      </>
                                    ) : (
                                      <span className='font-bold'>
                                        {formatCurrency(
                                          product.price.amount,
                                          product.price.currencyCode
                                        )}
                                      </span>
                                    )}
                                  </>
                                  <div className='mt-[12px] flex justify-between '>
                                    <button
                                      onClick={() => toggleProductsAdd(product)}
                                      className='border-[1px] border-bluePrimary hover:opacity-[.7] bg-bluePrimary text-white hover:border-none-600 disabled:border-grayDisabled disabled:text-textgrayDisabled font-medium text-white px-[1rem] py-[0.375rem] h-fit rounded-[4px] flex items-center uppercase text-[0.875rem] leading-[1.75] tracking-[0.02857em] space-x-3'
                                    >
                                      <AddCircleOutlineIcon className='fill-[#fff!important] text-[0.875rem!important] p-[2.6px]' />{" "}
                                      Agregar
                                    </button>
                                    <IconButton
                                      aria-label='delete'
                                      disabled={
                                        cart.items.some(
                                          (elem) => elem.id === product.id
                                        )
                                          ? false
                                          : true
                                      }
                                      onClick={() =>
                                        toggleProductsDelete(product)
                                      }
                                      className='hover:opacity-[.7] hover:text-white hover:border-none text-red bg-white disabled:border-grayDisabled disabled:text-textgrayDisabled font-medium text-white px-[.375rem] py-[0.375rem] h-fit rounded-[4px] items-center uppercase text-[0.875rem] leading-[1.75] tracking-[0.02857em] space-x-3'
                                    >
                                      <DeleteForeverIcon
                                        style={{
                                          fill: `${
                                            cart.items.some(
                                              (elem) => elem.id === product.id
                                            )
                                              ? "#ff3636"
                                              : "#0000001f"
                                          }`,
                                        }}
                                      />
                                    </IconButton>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                      )}
                      {categorie.id === 2 &&
                        products.map(
                          (product, indexProductDigital) =>
                            product.categoryId === 2 && (
                              <div
                                className='bg-white shadow-xl shadow-gray-600 rounded text-blackASC flex flex-col rounded-[15px]'
                                key={`productDigital-${indexProductDigital}`}
                              >
                                <div className='bg-bluePrimary bg-opacity-[.1] rounded-t-[15px]'>
                                  <img
                                    src={product.image || bookImg}
                                    className='w-[35%] mx-auto py-[10px]'
                                    alt='...'
                                  />
                                </div>
                                <div className='py-[0.7rem] px-[1rem] flex flex-col justify-between h-[100%]'>
                                  <div>
                                    <h3 className='text-bluePrimary text-[1.3rem] leading-none mb-[0.5rem]'>
                                      {product.name}
                                    </h3>
                                    <p className='card-description text-[0.8rem] pb-0'>
                                      {product.description}
                                    </p>
                                  </div>
                                  {/* <button className='text-[0.8rem] pt-[0px] leading-none'>Leer mas...</button> */}
                                  <div className='card-footer mt-[5px]'>
                                    <>
                                      {product.price.discountPercentage > 0 ? (
                                        <>
                                          <span className='font-bold mr-2'>
                                            {formatCurrency(
                                              product.price.amount,
                                              product.price.currencyCode
                                            )}
                                          </span>
                                          <span className='font-bold'>
                                            {formatCurrency(
                                              getFinalPrice(product.price),
                                              product.price.currencyCode
                                            )}
                                          </span>
                                        </>
                                      ) : (
                                        <span className='font-bold'>
                                          {formatCurrency(
                                            product.price.amount,
                                            product.price.currencyCode
                                          )}
                                        </span>
                                      )}
                                    </>
                                    <div className='mt-[12px] flex justify-between '>
                                      <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href={`https://www.amazon.com.mx/dp/${
                                          product.id === 226 ? "B077F83T56" : ""
                                        }${
                                          product.id === 227 ? "B077G7GV8G" : ""
                                        }${
                                          product.id === 228 ? "B077J7LK3S" : ""
                                        }${
                                          product.id === 229 ? "B077J7LK3S" : ""
                                        }/`}
                                        className='border-[1px] border-bluePrimary hover:opacity-[.7] bg-bluePrimary text-white hover:border-none-600 disabled:border-grayDisabled disabled:text-textgrayDisabled font-medium text-white px-[1rem] py-[0.375rem] h-fit rounded-[4px] flex items-center uppercase text-[0.875rem] leading-[1.75] tracking-[0.02857em] space-x-3'
                                      >
                                        Comprar
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                        )}
                    </div>
                  </>
                ))}
              </div>
              {counter > 0 && <button
                href='#cart'
                className='block md:hidden fixed bottom-[30px] right-[30px] bg-bluePrimary p-[10px] rounded-[50%]'
              >
                <Badge
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  badgeContent={counter}
                  color='primary'
                  sx={{"& .MuiBadge-badge": { background: '#ff4d4f'}}}
                  // className='text-[2.5rem]'
                >
                  <ShoppingCartIcon color='action' sx={{fontSize: '2.2rem', fill: '#fff'}} className='w-[2.5rem]' />
                </Badge>
              </button>}
            </div>
          </div>
        )}
      </section>
    </>
  );
};
export default Shop;
