import React from 'react'
import BackgroundCard from '../assets/logo.svg'
import { Link } from 'react-router-dom'
const Header = () => {
  return (
    <footer className='bg-white border-t-2 border-blueDark py-[16px] bottom-[0px] w-full'>
      <div className='container mx-auto '>
        <div className="flex flex-row justify-between">
          <a href="https://asc.education" target="_blank">
            <img src={BackgroundCard} className='w-[130px] md:w-[130px] mx-auto'/>
          </a>
          <div className="flex flex-row items-center">
            <Link className="mail" to="#">pagos@asc.education</Link>
            <Link className="phone ml-[10px]" to="#">01·800·841·77·42</Link>
          </div>
        </div>
        <div className="flex flex-row justify-end">
          <Link to="/politica" target="_blank" className="underline decoration-2">Políticas de Privacidad</Link>
          <Link to="/termns-conditions" target="_blank" className="underline decoration-2 ml-[10px]">Términos y condiciones</Link>
        </div>
      </div>
    </footer>
  )
}
export default Header
