import { useEffect } from "react";
import React from "react";
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import bookImg from '../assets/mindware.jpg';
import bannerBBVA from '../assets/bbv-ficha.png';
import bannerASC from '../assets/logo.svg';
import moment from 'moment';
const FormatPayment = () => {
  const format = JSON.parse(localStorage.getItem('depositFormat'))
  const formatCurrency = (amount, currencyCode = '') => {
    // console.log(amount)
    // return 0
    amount = amount.toString().replace(/\$|\,/g, '');
    if (currencyCode === undefined) currencyCode = '';
    else currencyCode = ' ' + currencyCode;
    if (isNaN(amount)) amount = '0';
    let sign = '';
    if (amount < 0) sign = '-';
    amount = Math.floor(amount * 100 + 0.501);
    let cents = amount % 100;
    amount = Math.floor(amount / 100).toString();
    if (cents < 10) cents = '0' + cents;
    for (var i = 0; i < Math.floor((amount.length - (1 + i)) / 3); i++) {
      amount = amount.substring(0, amount.length - (4 * i + 3)) + ',' + amount.substring(amount.length - (4 * i + 3));
    }
    return (sign + '$' + amount + '.' + cents + currencyCode);
  }
  const getPersonFullName = (person) => {
    let fullName = person.firstName ? `${person.firstName}` : '';
    fullName += person.lastName ? ` ${person.lastName}` : '';
    fullName += person.secondLastName ? ` ${person.secondLastName}` : '';
    return fullName;
  }
  return (
  <div style={{fontFamily: 'sans-serif'}}>
    {format !== null && 
      <>
        <div>
          <div className="title">
            <table width="100%">
              <tbody>
                <tr>
                  <td style={{padding: '5px', borderBottom: '1px solid grey', verticalAlign: "top", width:"100%" }} >
                    <h1 style={{ fontSize: '16px', textAlign: 'center'}}>REFERENCIAS PAGO DE SERVICIOS</h1>
                    <h3 style={{ fontSize: '12px', textAlign: 'center'}}>FICHA DE DEPÓSITO BANCOMER</h3>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{margin: "7px 0", position: "relative", height: '40px'}}>
            <div style={{width: '110px', height: "22px", position:'absolute', top: 0, left: 0}}>
              <img width="100%" height="auto" alt="" src={bannerASC} />
            </div>
            <div style={{width: '110px', height: "22px", position:'absolute', top: 0, right: 0}}>
              <img width="100%" height="auto" alt="" src={bannerBBVA} />
            </div>
          </div>
          <div style={{margin: '30px 0'}}>
            <table>
              <tbody>
                <tr>
                  <td width="50%">
                    <div className="small">
                      <h2 style={{padding: 0, margin: 0, fontSize: '16px', fontWeight: 'bold'}}>Depositar a nombre de: <span style={{fontWeight: 'normal'}}>EDITORIAL MORSAN INTERNACIONAL, S.A. DE C.V.</span></h2>
                    </div>
                    <h2 style={{padding: 0, margin: 0, fontSize: '16px', fontWeight: 'bold'}}>Número de convenio: <span style={{fontWeight: 'normal'}}>{format.paymentId}</span></h2>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="invoice-lines">
            <table style={{width: '100%'}}>
              <tbody style={{width: '100%'}}>
                <tr style={{padding: '5px', borderBottom: '1px solid grey'}} >
                  <td style={{padding: '5px', borderBottom: '1px solid grey', verticalAlign: 'top', width: '30%'}}>Referencia:</td>
                  <td style={{padding: '5px', borderBottom: '1px solid grey', verticalAlign: 'top', width: '70%'}}>
                    <h2 style={{padding: 0, margin: 0, fontSize: '16px', fontWeight: 'bold', textAlign: 'left'}}>{format.rap}</h2>
                  </td>
                </tr>
                <tr>
                  <td style={{padding: '5px', borderBottom: '1px solid grey', verticalAlign: 'top', width: '30%'}}>
                    Fecha de vencimiento:
                  </td>
                  <td style={{padding: '5px', borderBottom: '1px solid grey', verticalAlign: 'top', width: '70%'}}>
                    <h2 style={{padding: 0, margin: 0, fontSize: '16px', fontWeight: 'bold', textAlign: 'left'}}>
                      {moment(format.dueDate).format("DD/MM/YYYY")}
                    </h2>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {format.students && format.students.map((student, index) => (
            <table className="student-info"  key={index}>
              <tbody>
                <tr>
                  <td style={{padding: '5px', borderBottom: '1px solid grey', verticalAlign: 'top', width: '30%'}}>
                    Nombre:
                  </td>
                  <td style={{padding: '5px', borderBottom: '1px solid grey', verticalAlign: 'top', width: '70%'}}>
                    <h2 style={{padding: 0, margin: 0, fontSize: '16px', fontWeight: 'bold', textAlign: 'left'}}>
                      {getPersonFullName(student.person)}
                    </h2>
                  </td>
                </tr>
                <tr>
                  <td style={{padding: '5px', borderBottom: '1px solid grey', verticalAlign: 'top', width: '30%'}}>
                    Nombre:
                  </td>
                  <td style={{padding: '5px', borderBottom: '1px solid grey', verticalAlign: 'top', width: '70%'}}>
                    <h2 style={{padding: 0, margin: 0, fontSize: '16px', fontWeight: 'bold', textAlign: 'left'}}>
                      {getPersonFullName(student.person)}
                    </h2>
                  </td>
                </tr>
                <tr>
                  <td style={{padding: '5px', borderBottom: '1px solid grey', verticalAlign: 'top', width: '30%'}}>
                    Colegio:
                  </td>
                  <td style={{padding: '5px', borderBottom: '1px solid grey', verticalAlign: 'top', width: '70%'}}>
                    <h2 style={{padding: 0, margin: 0, fontSize: '16px', fontWeight: 'bold', textAlign: 'left'}}>
                      {student.school.name}
                    </h2>
                  </td>
                </tr>
                <tr>
                  <td style={{padding: '5px', borderBottom: '1px solid grey', verticalAlign: 'top', width: '30%'}}>
                    Nivel:
                  </td>
                  <td style={{padding: '5px', borderBottom: '1px solid grey', verticalAlign: 'top', width: '70%'}}>
                    <h2 style={{padding: 0, margin: 0, fontSize: '16px', fontWeight: 'bold', textAlign: 'left'}}>
                      {student.academicGrade.name}
                    </h2>
                  </td>
                </tr>
                {student.academicYear && 
                  <tr>
                    <td style={{padding: '5px', borderBottom: '1px solid grey', verticalAlign: 'top', width: '30%'}}>
                      Ciclo escolar:
                    </td>
                    <td style={{padding: '5px', borderBottom: '1px solid grey', verticalAlign: 'top', width: '70%'}}>
                      <h2 style={{padding: 0, margin: 0, fontSize: '16px', fontWeight: 'bold', textAlign: 'left'}}>
                        {student.academicYear.name}
                      </h2>
                    </td>
                  </tr>
                }
                <tr>
                  <td style={{padding: '5px', borderBottom: '1px solid grey', verticalAlign: 'top', width: '30%'}}>
                    Producto:
                  </td>
                  <td style={{padding: '5px', borderBottom: '1px solid grey', verticalAlign: 'top', width: '70%'}}>
                    {student.product.name}
                  </td>
                  <td style={{padding: '5px', borderBottom: '1px solid grey', verticalAlign: 'top', width: '70%'}}>
                    <h2 style={{padding: 0, margin: 0, fontSize: '16px', fontWeight: 'bold', textAlign: 'left'}}>
                      Importe: {formatCurrency(student.amount, 'MXN')}
                    </h2>
                  </td>
                </tr>
                <tr>
                  <td>Producto:</td>
                  <td>{student.product.name}</td>
                  <td width="30%">Importe: {formatCurrency(student.amount, 'MXN')}</td>
                </tr>
              </tbody>
            </table>
          ))}
          <div className="student-info">
            <table  style={{width: '100%'}}>
              <tbody>
                <tr className="total">
                  <td></td>
                  <td style={{padding: '5px', borderBottom: '1px solid grey', textAlign: 'right'}}>Total: {formatCurrency(format.amount, 'MXN')}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {format.fiscalData && format.fiscalData.businessName !== '' && 
            <table className="student-info">
              <tbody>
                <tr>
                  <td width="30%" colspan="3">
                    <h2 style={{ fontSize: '16px'}}>Datos de facturación:</h2>
                  </td>
                </tr>
                {format.fiscalData.businessName === 'ERROR_INVALID_FISCALDATA_ID'
                  ?
                    <tr>
                      <td>Hubo un error con los datos de facturación, contáctanos para más informes.</td>
                      <td></td>
                      <td></td>
                    </tr>
                  :
                    <>
                      <tr>
                        <td>Razón social:</td>
                        <td> <h2 style={{padding: 0, margin: 0, fontSize: '16px', fontWeight: 'bold', textAlign: 'left'}}>{format.fiscalData.businessName || 'Sin información'}</h2></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>RFC:</td>
                        <td> <h2 style={{padding: 0, margin: 0, fontSize: '16px', fontWeight: 'bold', textAlign: 'left'}}>{format.fiscalData.fiscalId || 'Sin información'}</h2></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Dirección:</td>
                        <td>
                          {(format.fiscalData.street || format.fiscalData.number) &&
                            <p style={{padding: 0, margin: 0, fontSize: '16px', fontWeight: 'bold', textAlign: 'left'}}>{`${format.fiscalData.street} ${format.fiscalData.number}, `}</p>
                          }
                          {format.fiscalData.neighborhood &&
                            <p style={{padding: 0, margin: 0, fontSize: '16px', fontWeight: 'bold', textAlign: 'left'}}>{` ${format.fiscalData.neighborhood}, `}</p>
                          }
                          {format.fiscalData.postalCode &&
                            <p style={{padding: 0, margin: 0, fontSize: '16px', fontWeight: 'bold', textAlign: 'left'}}>{` C.P. ${format.fiscalData.postalCode}`}</p>
                          }
                          {!format.fiscalData.postalCode &&
                            <p style={{padding: 0, margin: 0, fontSize: '16px', fontWeight: 'bold', textAlign: 'left'}}>Sin información</p>
                          }
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Ciudad:</td>
                        <td>
                          {format.fiscalData.locality &&
                            <p style={{padding: 0, margin: 0, fontSize: '16px', fontWeight: 'bold', textAlign: 'left'}}>{` ${format.fiscalData.locality}, `}</p>
                          }
                          {format.fiscalData.state &&
                            <p style={{padding: 0, margin: 0, fontSize: '16px', fontWeight: 'bold', textAlign: 'left'}}>{` ${format.fiscalData.state}, `}</p>
                          }
                          {format.fiscalData.countryId &&
                            <p style={{padding: 0, margin: 0, fontSize: '16px', fontWeight: 'bold', textAlign: 'left'}}>{` ${format.fiscalData.countryId}`}</p>
                          }
                        </td>
                        <td></td>
                      </tr>
                    </>
                }
              </tbody>
            </table>
          }
        </div>
        <div className="info-advice">
          <table>
            <tbody>
              <tr>
                <td><strong>Esta ficha de depósito solo es válida para los alumnos descritos en el documento. Fecha de vigencia: {moment(format.dueDate).format("DD/MM/YYYY")}</strong></td>
              </tr>
              <tr><td><b>Importante:</b> Guarda el comprobante de pago original para cualquier aclaración, sin este no será posible atender ninguna reclamación.</td></tr>
            </tbody>
          </table>
        </div>
      </>
    }
  </div>
  )
}
export default FormatPayment
