export const formatCurrency = (amount, currencyCode = '') => {
  amount = amount.toString().replace(/\$|\,/g, '');
  if (currencyCode === undefined) currencyCode = '';
  else currencyCode = ' ' + currencyCode;
  if (isNaN(amount)) amount = '0';
  let sign = '';
  if (amount < 0) sign = '-';
  amount = Math.floor(amount * 100 + 0.501);
  let cents = amount % 100;
  amount = Math.floor(amount / 100).toString();
  if (cents < 10) cents = '0' + cents;
  for (var i = 0; i < Math.floor((amount.length - (1 + i)) / 3); i++) {
    amount = amount.substring(0, amount.length - (4 * i + 3)) + ',' + amount.substring(amount.length - (4 * i + 3));
  }
  return (sign + '$' + amount + '.' + cents + currencyCode);
}
export const isRFC = (value) => {
  if (!value || !value.length) return true;
  const regexp = new RegExp(/^[A-Z]{3,4}[0-9]{6}[A-Z0-9]{3}$/g);
  if (!regexp.test(value)) return false;
  return true;
}
export const isRequired = (value) => {
  if (!value || !value.length) return false;
  return true;
}
// const getStudents = async(currentUserPerson) => {
//   const response = await dispatch(getStudentsToPayment(currentUserPerson.id))
//   let students = response.data;
//   if (students.length > 0) {
//     const selectedStudents = selectedStudents.map(
//       (studentId) => students.find((student) => student.student.id === studentId),
//     );
//     const schoolId = selectedStudents[0].school.id;
//     let orderShippingCountryCode = selectedStudents[0].school.countryCode;
//     const isDifferent = selectedStudents.find((student) => student.school.id !== schoolId);
//     let isSameSchool = isDifferent === undefined;
//     await dispatch(getDropdownPaymentInstallment(schoolId))
//   }
// }
const compareRoles = (allowedRoles, userRoles) => {
  let match = false;

  allowedRoles.forEach((allowedRole) => {
    userRoles.forEach((userRole) => {
      if (userRole === allowedRole) match = true;
    });
  });

  return match;
}
export const checkPermissions = (allowedRoles, currentUser) => {
  const userRoles = currentUser.roles.map((role) => (role.roleCode));
  const userRolesFamilies = currentUser.roles.map((role) => (role.role.familyCode));
  return compareRoles(allowedRoles, userRoles.concat(userRolesFamilies));
}
export const validatePayment = (fiscalData, fiscalId, isAbleToShipping, orderShippingId) => {
  if (fiscalData !== null) {
    let isValidRFC = isRFC(fiscalId)
    && isRequired(fiscalId);
    if (!isValidRFC) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      return false;
    }
  }
  if (isAbleToShipping && !orderShippingId) {
    // warningShippingMessage = 'Ingresa una dirección para tu entrega.';
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    return false;
  }
  return true;
}
