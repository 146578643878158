import React from 'react';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InputAdornment from '@mui/material/InputAdornment';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';

const FormInvoice = ({fiscalData, postalCode, handleInvoiceForm, handleInvoiceData, handleForm, handlePostalCodeInvoiceData}) => {
  console.log('aa',postalCode)
  return (
    <div className={`bg-white shadow-xl rounded-[15px] px-[20px] pt-[18px] pb-[20px] mt-[20px] md:mt-[0px] mb-4 text-blackASC min-w-[80%] lg:h-fit w-full flex justify-center flex-col`}>
      <h3 className='text-center my-[15px]'>ACTUALIZAR DATOS DE FACTURACIÓN</h3>
      <div className="flex flex-row items-center w-full mb-[12px] justify-center">
        <div className="flex flex-row items-center w-[50%]">
          <TextField
            required
            id="company_name"
            label="RAZÓN SOCIAL"
            variant="outlined"
            value={fiscalData.businessName}
            className="w-full bg-white"
            onChange={(e) => handleInvoiceData('businessName', e)}
          />
          <CheckCircleIcon 
            disabled={fiscalData.businessName !== '' ? false : true}
            className="ml-[5px]" 
            style={{fill: "#82CA9C", visibility: fiscalData.businessName !== null && fiscalData.businessName !== '' ? 'visible' : 'hidden' }} />
        </div>
        <p className='w-[239px] ml-[12px] pb-0'>Ingresa la razón social.</p>
      </div>
      <div className="flex flex-row items-center w-full mb-[12px] justify-center">
        <div className="flex flex-row items-center w-[50%]">
          <TextField
            required
            id="company_name"
            label="CORREO ELECTRÓNICO"
            variant="outlined"
            value={fiscalData.email}
            onChange={(e) => handleInvoiceData('email', e)}
            className="w-full bg-white"
          />
          <CheckCircleIcon 
            disabled={fiscalData.email !== '' ? false : true}
            className="ml-[5px]" 
            style={{fill: "#82CA9C", visibility: fiscalData.email !== null && fiscalData.email !== '' ? 'visible' : 'hidden' }} />
        </div>
        <p className='w-[239px] ml-[12px] pb-0'>Ingresa el correo electrónico.</p>
      </div>
      <div className="flex flex-row items-center w-full mb-[12px] justify-center">
        <div className="flex flex-row items-center w-[50%]">
          <TextField
            required
            id="postalCode"
            label="CÓDIGO POSTAL"
            variant="outlined"
            value={fiscalData.postalCode}
            disabled={fiscalData.state !== '' ? true : false}
            onChange={(e) => {
              handleInvoiceData('postalCode', e)
            }}
            className="w-full bg-white"
            InputProps={{ endAdornment:
              <InputAdornment position="end">
                {fiscalData.state !== ''
                  ?
                    <button
                      onClick={()=>handlePostalCodeInvoiceData('clear')}
                      className="bg-blueDarken hover:bg-grayDisabled text-white w-[2.2rem] h-[2.2rem] rounded-full"
                    >
                      <EditIcon style={{fill: "#fff"}} /> 
                    </button>
                  :
                    <button
                      // disabled={fiscalId && fiscalId.length !== 13 ? true : false}
                      onClick={()=>handlePostalCodeInvoiceData()}
                      className={fiscalData.postalCode.length === 5 ? `bg-blueDarken hover:bg-grayDisabled text-white w-[2.2rem] h-[2.2rem] rounded-full` : `bg-grayDisabled text-white w-[2.2rem] h-[2.2rem] rounded-full cursor-none`}
                    >
                      <SearchIcon style={{fill: "#fff"}} /> 
                    </button>
                }
              </InputAdornment>
            }}
          />
          <CheckCircleIcon 
            disabled={fiscalData.postalCode !== '' ? false : true}
            className="ml-[5px]" 
            style={{fill: "#82CA9C", visibility: fiscalData.postalCode !== null && fiscalData.postalCode !== '' ? 'visible' : 'hidden' }} />
        </div>
        <p className='w-[239px] ml-[12px] pb-0'>Ingresa el código postal.</p>
      </div>
      <div className="flex flex-row items-center w-full mb-[12px] justify-center">
        <div className="flex flex-row items-center w-[50%]">
          <FormControl className='bg-white' fullWidth>
            <InputLabel id="demo-simple-select-label" className="" >Colonia, Ciudad y Estado:</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={fiscalData.neighborhood}
              onChange={(e) => handleInvoiceData('neighborhood', e)}
            >
              {postalCode && postalCode.map((option, index) => {
                return (
                  <MenuItem key={index} value={option}>
                    {option.neighbourhood}, {option.locality}, {option.state}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <CheckCircleIcon 
            disabled={fiscalData.neighborhood !== '' ? false : true}
            className="ml-[5px]" 
            style={{fill: "#82CA9C", visibility: fiscalData.neighborhood !== null && fiscalData.neighborhood !== '' ? 'visible' : 'hidden' }} />
        </div>
        <p className='w-[239px] ml-[12px] pb-0'>Ingresa el código postal.</p>
      </div>
      <div className="flex flex-row items-center w-full mb-[12px] justify-center">
        <div className="flex flex-row items-center w-[50%]">
          <TextField
            required
            id="company_name"
            label="CALLE:"
            variant="outlined"
            value={fiscalData.street}
            className="w-full bg-white"
            onChange={(e) => handleInvoiceData('street', e)}
          />
          <CheckCircleIcon 
            disabled={fiscalData.street !== '' ? false : true}
            className="ml-[5px]" 
            style={{fill: "#82CA9C", visibility: fiscalData.street !== null && fiscalData.street !== '' ? 'visible' : 'hidden' }} />
        </div>
        <p className='w-[239px] ml-[12px] pb-0'>Ingresa la calle.</p>
      </div>
      <div className="flex flex-row items-center w-full mb-[12px] justify-center">
        <div className="flex flex-row items-center w-[50%]">
          <TextField
            required
            id="company_name"
            label="NÚMERO EXTERIOR:"
            variant="outlined"
            value={fiscalData.internalNumber}
            className="w-full bg-white mr-[5px!important]"
            onChange={(e) => handleInvoiceData('internalNumber', e)}
          />
          <TextField
            required
            id="company_name"
            label="NÚMERO INTERIOR:"
            variant="outlined"
            value={fiscalData.number}
            className="w-full bg-white ml-[5px!important]"
            onChange={(e) => handleInvoiceData('number', e)}
          />
          <CheckCircleIcon 
            disabled={(fiscalData.internalNumber !== '' && fiscalData.number !== '') ? false : true}
            className="ml-[5px]" 
            style={{fill: "#82CA9C", visibility: fiscalData.internalNumber !== null && fiscalData.internalNumber !== '' ? 'visible' : 'hidden' }} />
        </div>
        <p className='w-[239px] ml-[12px] pb-0'>Ingresa el número del domicilio.</p>
      </div>
      <div className='flex mx-auto'>
        <button onClick={()=> handleForm(false, null)} className='mx-[5px] border-[1px] border-blueDark hover:border-blueDarken-600 text-blueDarken bg-white disabled:border-grayDisabled disabled:text-textgrayDisabled font-medium text-white px-[1rem] py-[0.375rem] h-fit rounded-[4px] items-center uppercase text-[0.875rem] leading-[1.75] tracking-[0.02857em] space-x-3 mx-[10px]'>Cancelar</button>
        <button onClick={()=> handleInvoiceForm('edit')} className="bg-blueDarken disabled:bg-grayDisabled disabled:text-textgrayDisabled font-medium text-white inline-flex px-[1rem] py-[0.375rem] h-fit rounded-[4px] items-center uppercase text-[0.875rem] leading-6 tracking-[0.02857em] space-x-3 mx-[10px]">Enviar Datos</button>
      </div>
    </div>
  )
}
export default FormInvoice
