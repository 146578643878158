import React, {useEffect} from 'react'
import logoASC from '../assets/logo.svg'
import logoASCPagos from '../assets/logo.svg'
import { Link } from 'react-router-dom'
const Header = () => {
 
  return (
    <>
      <header className='py-[15px] px-[20px] flex justify-between text-gray shadow-xl shadow-gray-600 z-[90!important]'>
        <a rel="noreferrer" href="https://asc.education/">
          <img src={logoASC} className='h-[40px]' alt="Logo" />
        </a>
        <ul class="flex flex-row items-center">
          <li className="px-[0.7rem] py-[0px]">
            <a className="nav-link" href="https://asc.education/index.html#quienes_somos">¿Quiénes somos?</a>
          </li>
          <li className="px-[0.7rem] py-[0px]">
            <a className="nav-link dropdown-item py-2 px-3" href="https://pagos.asc.education/"> Pagos</a>
          </li>
          <li className="px-[0.7rem] py-[0px]">
            <a className="nav-link dropdown-item py-2 px-3" href="https://tienda.asc.education/"> Tienda ASC</a>
          </li>
        </ul>
      </header>
    </>
  )
}
export default Header
