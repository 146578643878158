import { combineReducers } from '@reduxjs/toolkit';
import paymentReducer from './paymentReducer';
import shopReducer from './shopReducer';

const createReducer = asyncReducers =>
	combineReducers({
		shopReducer,
		paymentReducer,
		...asyncReducers
	});

export default createReducer;
