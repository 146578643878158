import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
// import { useSelector } from 'react-redux';
import axios from 'axios';
import { API_CONFIG } from '../serverConfig'
import history from '../history';

export const decodeToken = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(window.atob(base64));
}

export const setTitle = (title, subTitle) =>  dispatch => {
	dispatch(setTitles({title:title, subtitle:subTitle}))
}
export const setStoreCart = (e) =>  dispatch => {
	dispatch(setCart(e))
}
export const authenticate = (username, password) => async dispatch => {
	try {
		const response = await axios.post(`${API_CONFIG.API_URL}users/authenticate`, { username, password }, { headers: { 'x-app-id': `${API_CONFIG.APP_ID}`}})
		const data = await response.data.jwt;
		localStorage.setItem('access_token', data)
		dispatch(setAccessToken(data))
		let decodedToken = decodeToken(data);
		const token = localStorage.getItem('access_token') === undefined ? data : localStorage.getItem('access_token');
		let responseUserData = await axios.get(`${API_CONFIG.API_URL}users/uuid/` + decodedToken.uuid, { headers: {'x-app-id': `${API_CONFIG.APP_ID}`, 'Authorization': `Bearer ${token}`}});
		dispatch(setCurrentUser(responseUserData.data));
		dispatch(loginSuccess())
		return true
	}
	catch {
		return false
	}
}
export const sendMail = (newUserName) => async dispatch => {
	const token = localStorage.getItem('access_token')
	const response = await axios.post(`${API_CONFIG.API_URL}payments/registration`, {	email: newUserName	}, { headers: {'x-app-id': `${API_CONFIG.APP_ID}`, 'Authorization': `Bearer ${token}`}})
}
export const getAcademicLeveles = () => async dispatch => {
	const token = localStorage.getItem('access_token')
	const response = await axios.get(`${API_CONFIG.API_URL}academic-levels/country/MX`, { headers: {'x-app-id': `${API_CONFIG.APP_ID}`, 'Authorization': `Bearer ${token}`}})
	dispatch(setAcademicLeveles(response.data))
}
export const getPaymentReference = (paymentReference) => async dispatch => {
	try {
		console.log(paymentReference)
		const response = await axios.get(`${API_CONFIG.API_URL}payments/webpay-response/${paymentReference}`, { headers: {'x-app-id': `${API_CONFIG.APP_ID}`}});
		return response;
	}
	catch {
		return false;
	}
}
export const webPayResponse = (paymentReference) => async dispatch => {
	try {
		console.log(paymentReference)
		const response = await axios.get(`${API_CONFIG.API_URL}payments/webpay-response/${paymentReference}`, { headers: {'x-app-id': `${API_CONFIG.APP_ID}`}});
		return response;
	}
	catch {
		return false;
	}
}
export const logOutt = () => async dispatch => {
	dispatch(authError())
}
export const checkAuth = () => async dispatch => {
	try {
		const token = localStorage.getItem('access_token')
		await axios.post(`${API_CONFIG.API_URL}users/reauthenticate`, {jwt: token}, { headers: {'x-app-id': `${API_CONFIG.APP_ID}`}})
		let decodedToken = decodeToken(token);
		let responseUserData = await axios.get(`${API_CONFIG.API_URL}users/uuid/` + decodedToken.uuid, {headers: {'x-app-id': `${API_CONFIG.APP_ID}`, 'Authorization': `Bearer ${token}`}});
		dispatch(setCurrentUser(responseUserData.data));
		dispatch(loginSuccess())
		return true
	}
	catch {
		localStorage.clear();
		history.push('/login');
		return false
	}
};


const eventsAdapter = createEntityAdapter({});

export const { selectAll: selectEvents, selectById: selectEventsById } = eventsAdapter.getSelectors(
	state => state.App.auth
);

const authSlice = createSlice({
	name: 'App/auth',
	initialState:eventsAdapter.getInitialState({
		isAuthenticated: null,
		isLoading: true,
		titles: {
			title: null,
			subtitle: null
		},
		academicLevels: [],
		currentUser: {
			roles: []
		},
		token: null,
		open: false
	}),
	reducers: {
		setCurrentUser: (state, action) => {
			state.currentUser = action.payload;
		},
		loginSuccess: (state, action) => {
			state.isAuthenticated = true;
			state.isLoading = false;
		},
		setAcademicLeveles: (state, action) => {
			state.academicLevels = action.payload
		},
		authError: (state, action) => {
			state.isAuthenticated = false;
			state.isLoading = false;
		},
		setTitles: (state, action) => {
			state.titles = action.payload;
		},
		setCart: (state, action) => {
			state.cart = action.payload;
		},
		changeOpen: (state, action) => {
			state.open = action.payload;
		},
		setAccessToken: (state, action) => {
			state.token = action.payload;
		},
	},
	extraReducers: {}
});

export const { loginSuccess, authError, setAcademicLeveles, setCurrentUser, setTitles, changeOpen, setAccessToken, setCart } = authSlice.actions;

export default authSlice.reducer;
