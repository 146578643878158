import React from 'react';
import Footer from '../components/FooterExtra'
import Header from '../components/HeaderExtra'
const Politics = () => {
  return (
    <>
      <Header />
      <div className="container my-[3.5rem]">
        <h2 className="text-center mb-[1.5rem]">AVISO DE PRIVACIDAD. EDITORIAL MORSAN INTERNACIONAL, S.A</h2>
        <p className="">
          EDITORIAL MORSAN INTERNACIONAL, S.A. de C.V. en lo sucesivo “EMI", a través del "Sistema educativo ASC”, su sitio <a target="_blank" rel="noreferrer" className="underline decoration-2" href="//www.asc.education">www.asc.education</a> y
          las páginas o sitios hospedados, ubicados o ligados al o en la dirección electrónica <a target="_blank" rel="noreferrer" className="underline decoration-2" href="//www.asc.education">www.asc.education</a> en adelante como “el portal”,
          tiene entre sus objetivos, la convicción de proteger los datos personales, y datos personales sensibles, en su caso, proporcionados por los usuarios; así como regirnos por los principios de licitud, consentimiento, información, calidad,
          finalidad, lealtad, proporcionalidad y responsabilidad en el tratamiento de los mismos (en adelante, y en su conjunto como la “información”). Por lo anterior, “EMI" ha establecido las siguientes disposiciones para proteger dicha
          información en la medida de lo posible, las cuales pueden cambiar en cualquier momento. Por lo que sugerimos consultarlas periódicamente. Los términos que no estén expresamente definidos en estas disposiciones, tendrán el significado
          que se les atribuye a los mismos en las Condiciones de Uso..
        </p>
        <p className="">
          1. <span className="font-semibold">INFORMACIÓN PERSONAL SOLICITADA.</span> “EMI" podrá solicitar la “información” a los usuarios, con el objeto de poder proporcionarles la mayor experiencia personalizada en el uso de los servicios que
          se les otorga a través del portal, así como para generar bases de datos que “EMI" pueda utilizar y transmitir a nivel nacional e internacional, para fines comerciales distintos, pero no ajenos a la compañía. El tipo de “información” que
          se solicita a los usuarios variará dependiendo del perfil con el que se solicite el registro dentro del portal” e incluirá dicho perfil el nombre(s), apellido(s), dirección de correo electrónico, teléfono, datos fiscales, datos
          laborales en el caso del perfil “Docente” o “Directivo", datos sobre la escuela a la cual acuda en el caso del perfil “Alumno” y/o “Padre de Familia", datos sobre la escuela a la cual acuda(n) su (s) hijo (os) y dirección de domicilio..
        </p>
        <p className="">
          No toda la “información” solicitada al usuario al momento de registrarse es obligatoria de proporcionarse, salvo aquella que “EMI" considere conveniente y que así se le haga saber al usuario al momento del registro, por lo que
          solicitará dicha “información” cuando considere sea oportuno o necesario; como por ejemplo: en las encuestas puestas en práctica para evaluar y/o proporcionar de manera más satisfactoria los servicios, o bien cuando los usuarios quieran
          darse de alta con calidad de “Docente”, “Alumno”, ”Directivo" y/o “Padre de Familia”; o bien hacer una compra de las obras que EDITORIAL MORSAN INTERNACIONAL edita y comercializa. Para efectos de esto último, “EMI" invariablemente le
          solicitará al usuario que inicie sesión con una dirección de correo electrónico y una contraseña. Los usuarios de los servicios deberán otorgar su consentimiento libre y voluntariamente para proporcionar la “información” que le sea
          requerida, en el entendido de que si el usuario optara por no proporcionar la “información” obligatoria, dicho usuario no podrá acceder a los servicios para los cuales se requiere..
        </p>
        <p className="">
          2. <span className="font-semibold">USO, FINALIDAD Y TRATAMIENTO DE LA INFORMACIÓN.</span> “EMI" también recolectará “información” que es derivada de los gustos, preferencias y en general de la utilización que hacen los usuarios de los
          servicios. Dicha “información” derivada, al igual que la “información” personal que los usuarios proporcionen, podrá ser utilizada para diversos objetivos comerciales, como lo es el proporcionar datos estadísticos, enviar publicidad a
          los usuarios de acuerdo a sus intereses específicos, conducir investigaciones de mercadeo, y otras actividades o promociones que “EMI" considere apropiadas. “EMI" también podrá revelar “información” cuando por mandato de ley y/o de
          autoridad competente le fuere requerido, o por considerar de buena fe que dicha revelación es necesaria para: i) Cumplir con procesos legales; ii) Cumplir con el convenio del usuario; iii) Responder reclamaciones que involucren
          cualquier contenido que menoscabe derechos de terceros, o; iv) Proteger los derechos, la propiedad o la seguridad de “EMI", sus usuarios y el público en general..
        </p>
        <p className="">“EMI” podrá transmitir la “información” proporcionada por sus usuarios a terceros que tengan cualquier relación jurídica con aquél, ya sea a nivel nacional o internacional..</p>
        <p className="">
          “EMI” advierte a sus usuarios que alguno de los servicios del portal son bitácoras o diarios (blogs) los cuales son monitoreados por él, sin embargo es importante recalcar que toda la “información” que los usuarios revelen por medio de
          dichos servicios podrá ser vista o utilizada por terceros. “EMI” no será responsable por el uso, publicación o revelación de “información” que los usuarios proporcionen, por lo que recomendamos que sean cuidadosos y responsables sobre
          dicha “información” que tendrá el carácter de público. “EMI" se reserva el derecho de ceder la “información” proporcionada por los usuarios a cualquiera de sus filiales o subsidiarias, ya sean presentes, o aquellas que se constituyan o
          adquieran en el futuro, a nivel nacional, como internacional..
        </p>
        <p className="">
          3. <span className="font-semibold">PROTECCIÓN DE LA INFORMACIÓN.</span> “EMI" expresa su compromiso de proteger la seguridad de la “información” proporcionada por los usuarios. Para dichos fines, utilizamos una gran variedad de
          tecnologías y procedimientos de seguridad, para impedir, en la medida de lo posible, el acceso, uso o divulgación no autorizados. Cuando se transmite “información “altamente confidencial a través de la red internet, la protegemos
          mediante encriptación bajo el protocolo Secure Socket Layer (SSL). No obstante lo anterior será responsabilidad del usuario el que permanezca con carácter de confidencial, el uso de cualquier contraseña..
        </p>
        <p className="">
          4. <span className="font-semibold">COOKIES.</span> El portal usa diversas “cookies” para iniciar sesión, y así el usuario pueda utilizar algunos de nuestros servicios. Las “cookies” contienen “información”, que posteriormente puede leer
          un servidor web con el objetivo de almacenar sus preferencias y otro tipo de “información” en el equipo, para que de este modo, ahorrar tiempo al usuario, al eliminar la necesidad de escribir varias veces la misma “información”; además,
          permite mostrar contenidos personalizados..
        </p>
        <p className="">
          5. <span className="font-semibold">DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U OPOSICIÓN DEL USO DE INFORMACIÓN.</span> El usuario podrá ejercer sus derechos ARCO que les confiere la ley, dirigiendo su solicitud en atención al
          titular del Departamento de Privacidad y Datos Personales mediante: (i) correo electrónico a la dirección <a href="mailto:info@asc.education">info@asc.education</a> o bien; (ii) en el siguiente domicilio: Avenida Álamos, lote 1, manzana
          19, Supermanzana 311 en Cancún, Quintana Roo, C.P. 77560, teléfono 800 841 77 42, en días hábiles de las 9:00 a las 16:00 horas. Para lo anterior, deberá hacernos saber fehacientemente mediante documento escrito físico y/o electrónico
          según corresponda, los datos personales que usted desea sean rectificados, cancelados o revisados, así como el propósito para el cual los aportó, cumpliendo con los siguientes requisitos: a) Incluir el nombre y firma autógrafa del
          Titular, así como un domicilio y/o dirección de correo electrónico para comunicarle la respuesta a su solicitud. b) Acompañar los documentos oficiales que acrediten la identidad del titular. c) Incluir una descripción clara y precisa de
          los datos personales respecto de los cuales ejercita los derechos que la ley le confiere. d) Incluir cualquier elemento o documento que facilite la localización de los datos personales de que se traten..
        </p>
        <p className="">
          6. <span className="font-semibold">CAMBIOS EN EL AVISO DE PRIVACIDAD.</span> El presente aviso de privacidad, podrá ser actualizado, con la finalidad de reflejar los cambios que se produzcan en nuestros servicios. En dicho supuesto, se
          modificará la fecha de “última actualización”, en la parte superior de la misma. Si se realizan cambios materiales en este aviso de privacidad, o bien en la forma que “EMI" usa y/o transmite la información personal, se le notificará con
          anterioridad a su implementación al usuario, mediante un comunicado al correo electrónico que haya dispuesto..
        </p>
        <p className="">
          7. <span className="font-semibold">CONTACTO.</span> En caso de dudas y/o comentarios a este aviso de privacidad, el usuario podrá contactar a “EMI" mediante comunicado electrónico al correo
          <a href="mailto:info@asc.education">info@asc.education</a> obteniendo una respuesta sobre el mismo, en un plazo de 72 horas hábiles..
        </p>
        <p className="">
          8. <span className="font-semibold">MARCO LEGAL VIGENTE.</span> El usuario se obliga, por el simple hecho de navegar a través del portal, a respetar toda la normativa legal vigente relacionada con la utilización, gestión y uso de
          sistemas de información. “EMI" extiende el presente aviso de privacidad, de conformidad con lo dispuesto por la Ley Federal de Protección de Datos Personales, así como por las normas aplicables a nivel nacional e internacional..
        </p>
        <p className="">© EDITORIAL MORSAN INTERNACIONAL, S.A. ® ASC Cancún, Quintana Roo. Todos los derechos reservados.</p>
      </div>
      <Footer />
    </>
  )
}
export default Politics
