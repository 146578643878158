import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
// import { uuid } from 'uuid';
import { v4 as uuidv4 } from 'uuid';
import { setTitle } from '../store/authReducer';
import { setStep, searchRFC, getCountries, setOpen, setOrderShippingId, postFiscalData, putFiscalData, getMexicoPostalCodes, getMexicoPostalCode, postDirectionData, putDirectionData, cleanFicalData, getAcademicYearsPaying, createDepositFormat, setPersonAddress, changeAdres, createSantanderUrl} from '../store/shopReducer';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from "react-router-dom";
import { formatCurrency, isRFC, isRequired, validatePayment, createFormat,checkP, checkPermissions } from '../helpers/paymentFunctions';
// import { setUserUuid, setIsAsc, setFiscalData, setOrderShippingId, setPayingCart, setDepositFormat, setPaymentUUID, setCardPaymentVoucher, setPaymentOrigin, getUserUuid, getIsAsc, getFiscalData, getOrderShippingId, getPayingCart, getDepositFormat, getPaymentUUID, getCardPaymentVoucher, getPaymentOrigin, remove } from '../helpers/localstorage';

const usePayment = () => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const { addToast } = useToasts();
  const [needInvoice, setNeedInvoice] = useState(null)
  const [isAgree, setIsAgree] = useState(false)
  // const totalAmount = useSelector(({ shopReducer }) => shopReducer.personAddress);
  const personAddress = useSelector(({ shopReducer }) => shopReducer.personAddress);
  // const currentCurrencyCode = useSelector(({ shopReducer }) => shopReducer.personAddress);
  const fiscalD = useSelector(({ shopReducer }) => shopReducer.fiscalData);  
  const postalCodes = useSelector(({ shopReducer }) => shopReducer.postalCode);  
  const countries = useSelector(({ shopReducer }) => shopReducer.countries);  
  const adress = useSelector(({ shopReducer }) => shopReducer.adress);  
  const postalCode = useSelector(({ shopReducer }) => shopReducer.postalCodes);  
  const orderShipping = useSelector(({ shopReducer }) => shopReducer.orderShipping);
  const cart = useSelector(({ shopReducer }) => shopReducer.cart);
  const [directionData, setDirectionData] = useState({ name: '', school: '', street: '' , number: '', 'internalNumber': '', 'countryCode': '', 'postalCode': '',  'state': '', 'neighborhood': '',  'stateId': '', 'countyId': '', 'localityId': '', 'locality': '', 'reference': '', 'phoneNumber': '', 'email': '', 'personId': 68875 })
  const [fiscalData, setFiscalData] = useState(null)
  const [fiscalId, setFiscalId] = useState('')
  const [showParticipantsCards, setShowParticipantsCards] = useState(false)
  const [format, setFormat] = useState(null)
  const [paymentURL, setPaymentURL] = useState({})
  const [isFormatPayment, setIsFormatPayment] = useState(true)
  const isAbleToShipping = true;
  const [creditPaymentOption, setCreditPaymentOption] = useState('')
  const [cuponActive, setCuponActive] = useState(false)
  const creditPaymentOptions = ([{text: 'Selecciona tus meses',value: ''},{text: '3 meses',value: 3},{text: '6 meses',value: 6}])
  // const cart = JSON.parse(localStorage.getItem('payingCart'));
  const [totalAmount, setTotal] = useState(0);
  const [directionItem, setDirectionIte] = useState(null);
  const currencyCode = 'MXN';

  let isOk;
  const init = async(title, subtitle, step) => {
    if(cart === null) {
      navigate.push("/")
      localStorage.clear();
      return false
    }
    setTotal(cart.reduce(function(prev, cur) {
      return prev + (cur.price.amount * cur.cartQuantity);
    }, 0))
    handleStep('direction')
  }
  // const handlePaymentOptions = async() => {
  //   console.log('handlePaymentOptions')
  // }
  // const checkUserASC = () => {
  //   if (!person) return false;
  //   console.log(checkPermissions(['ASC'], person))
  //   return checkPermissions(['ASC'], person) || false;
  // }
  // const storeData = () => {
  //   setUserUuid(person.uuid);
  //   let isASC = checkUserASC()
  //   setIsAsc(isASC);
  //   if (!needInvoice) {
  //     setFiscalData(JSON.stringify({
  //       isInvoiced: true,
  //       data: fiscalData,
  //       cfdiUseId: 0,
  //     }));
  //   } else {
  //     setFiscalData(JSON.stringify({
  //       isInvoiced: false,
  //     }));
  //   }
  // }
  const goToCardPayment = async(creditPayment = false, creditPaymentOptions) => {
    try {
      await getPaymentUrl();
    } catch (error) {
      console.log(error);
    } finally {
      // this.submitting = false;
    }
  }
  const getPaymentUrl = async(creditPayment = false) => {
    try {
      const paymentUUID = uuidv4()
      // remove('paymentUUID');
      // setPaymentUUID(paymentUUID);
      // let response = await dispatch(getStudentsToPayment(person.personId))
      // console.log(fiscalD)
      // dispatch(setStudents(response.data))
      const body = {
        paymentUUID,
        personId: 68875,
        fiscalDataId: needInvoice ? parseInt(fiscalD.data.id, 10) : 0,
        cfdiUseId:  needInvoice ? fiscalD.cfdiUseId : 0,
        installmentPlan: creditPayment ? parseInt(creditPayment, 10) : 0,
        orderShippingId: orderShipping,
        shop: "1",
        Coupon: cuponActive ? directionData.cupons : null,
        orderData: cart.map((product) => ({
          productId: parseInt(product.id, 10),
          quantity: parseInt(product.cartQuantity, 10),
        })),
      };
      let response = await dispatch(createSantanderUrl(body));
      if (response.data) {
        setFormat(response)
        setPaymentURL(response.data.URL);
        handleStep('BankPlataform')
      }
      else addToast('No se pudo obtener la URL del banco', { appearance: 'error', autoDismiss: true })
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  const searcFiscalID = async() => {
    // let isRFCOk = await isRFC(fiscalId)
    // if (!isRFCOk) {
    //   addToast('Tu RFC no es valido', { appearance: 'error', autoDismiss: true })
    //   return
    // }
    let response = await dispatch(searchRFC(fiscalId))
    if(!response) {
      setFiscalData({id: 0, fiscalId: fiscalId, internationalFiscalId: '',businessName: '',street: '',number: '',internalNumber: '',neighborhood: '',locality: '',reference: '',state: '',countryId: 'MX',postalCode: '',isMexican: true, email: '',country: {isoCodeTwo: 'MX',isoCodeThree: 'MEX',currencyCode: 'MXN',languageCode: 'es', name: 'México'}})
    }
    else {
      setFiscalData(response)
    }
  } 
  // const getDropdownPaymentInstallment = async(schoolId) => {
  //     isOk = await dispatch(getPaymentInstallmentBySchool(schoolId))
  //     const options = isOk.data.map((option) => ({
  //       text: option.label || option.text,
  //       value: option.value,
  //     }));
  //     setCreditPaymentOptions(options)
  // }

  /* Steps */
  const handleDirectionStep  = async() => {
    if(cuponActive && directionData.cupons !== 'ASC01' && directionData.cupons !== 'ASC02' ) {
      addToast('Tu cupon no es Valido', { appearance: 'warning', autoDismiss: true })
      return
    }
    let body = {
      name: directionData.name,
      school: directionData.school,
      countryCode: directionData.countryCode,
      email: directionData.email,
      internalNumber: directionData.internalNumber,
      localityString: directionData.locality,
      number: directionData.number,
      personId: 68875,
      phoneNumber: directionData.phoneNumber,
      postalCodeString: directionData.postalCode,
      reference: directionData.reference,
      stateString: directionData.state,
      street: directionData.street,
      Coupon: directionData.cupons ? directionData.cupons : null
    };
    let isOk = await dispatch(postDirectionData(body))
    if(!isOk) {
      addToast('Ocurrio un error!', { appearance: 'warning', autoDismiss: true })
      return
    }
    else {
      dispatch(setOrderShippingId(isOk.data.id))
      handleStep('pay')
    }
  }
  const handleStep  = async(prop) => {
    if(prop === 'invoice') {
      dispatch(setStep(3));
      dispatch(setTitle('Datos de facturación'));
      let e = JSON.parse(localStorage.getItem('payingCart')).reduce(function (a, b) {
        return a + (b.price.amount * b.cartQuantity)
      }, 0)
      setTotal(e);
    }
    else if(prop === 'direction') {
      localStorage.setItem('fiscalData', needInvoice ? false : JSON.stringify(fiscalD))
      dispatch(setStep(2));
      dispatch(getCountries());
      dispatch(setTitle('Dirección de envio'));
    }
    else if(prop === 'pay') {
      dispatch(changeAdres(directionItem))
      dispatch(setStep(4));
      dispatch(setTitle('Forma de pago'));
    }
    else if(prop === 'formatPayment') {
      // dispatch(setPersonAddress(5));
      dispatch(setStep(5));
      dispatch(setTitle('Refencia de Pago'));
    }
    else if(prop === 'BankPlataform') {
      dispatch(setStep(5));
      setIsFormatPayment(false)
      dispatch(setTitle('Pago con tarjeta'));
    }
    
  }
  /* Invoice */
  const handleInvoice = (prop) => {
    setNeedInvoice(prop);
    setFiscalId('')
    dispatch(cleanFicalData())
  }
  // const handleForm = (e, prop) => {
  //   dispatch(setOpen(e, prop))
  // }
  const handleInvoiceForm = async(prop, event) => {
    if(fiscalD === null) {
      isOk = await dispatch(postFiscalData(fiscalData))
      if(!isOk) {
        addToast('Revisa tu información para emitir tu factura', { appearance: 'error', autoDismiss: true })
        return
      }
      else {
        isOk = await dispatch(searchRFC(fiscalId))
        if(!isOk) addToast('Es necesario que ingreses un R.F.C valido para emitir tu factura', { appearance: 'error', autoDismiss: true })
        else {
          dispatch(setOpen(false, null))
        }
      }
    }
    else {
      isOk = await dispatch(putFiscalData(fiscalData))
      if(!isOk) {
        addToast('Comunicarte con Misael', { appearance: 'error', autoDismiss: true })
        return
      }
      else {
        isOk = dispatch(searchRFC(fiscalId))
        if(!isOk) addToast('Comunicarte con Misael', { appearance: 'error', autoDismiss: true })
        else {
          dispatch(setOpen(false, null))
        }
      }
    }
  }
  const handleInvoiceData = (prop, event) => {
    if(prop === 'locality' || prop === 'state') {
      setFiscalData({...fiscalData,
        [prop]: event.target.value[prop]
      })
    }
    else if(prop === 'neighborhood') {
      setFiscalData({...fiscalData,
        [prop]: event.target.value.neighbourhood
      })
    }
    else {
      setFiscalData({...fiscalData,
        [prop]: event.target.value
      })
    }
  }
  
  const handlePostalCodeInvoiceData = async(prop) => {
    if(prop === 'clear') {
      setFiscalData({...fiscalData,
        stateId: '',
        // localityId: '',
        // countyId: '',
        state: '',
        locality: '',
        postalCode: '',
        neighborhood: ''
      })
    }
    else {
      let result = await dispatch(getMexicoPostalCodes(fiscalData.postalCode))
      setFiscalData({...fiscalData,
          // stateId: result[0].state.id,
          // localityId: result[0].locality.id,
          countyId: result[0].countyId,
          state: result[0].state.name,
          locality: result[0].locality.name
      })
    }
  }
  const handlePostalCodeDirectionData = async(prop) => {
    if(prop === 'clear') {
      setDirectionData({...directionData,
        stateId: '',
        localityId: '',
        countyId: '',
        state: '',
        locality: '',
        postalCodeId: '',
        neighborhood: ''
      })
    }
    else {
      let result = await dispatch(getMexicoPostalCode(directionData.postalCodeId, directionData.countryCode))
      if(result.length === 0) addToast('Tu codigo postal no es valido, o no aparece en nuestro registro', { appearance: 'warning', autoDismiss: true })

      else {
        setDirectionData({...directionData,
          stateId: result[0].state.id,
          localityId: result[0].locality.id,
          countyId: result[0].countyId,
          state: result[0].state.name,
          locality: result[0].locality.name
      })
    }
    }
  }
  const handleDirectionData = (prop, event) => {
    if(prop === 'postalCodeId') {
      setDirectionData({...directionData,
        [prop]: event.target.value ? event.target.value : '',
      })
    }
    else if(prop === 'neighborhood') {
      setDirectionData({...directionData,
        neighborhood: event.target.value ? event.target.value : '',
        postalCodeId: event.target.value.id
      })
    }
    else {
      setDirectionData({...directionData,
        [prop]: event.target.value
      })
    }
  }
  const handleDirection = (prop) => {
    setNeedInvoice(prop);
  }
  const handleDirectionForm = async(type) => {
    let data = {
      countryCode: directionData.countryCode, countyId: directionData.countyId, email: directionData.email, internalNumber: directionData.internalNumber, localityId: directionData.localityId, number: directionData.number, personId: 68875, phoneNumber: directionData.phoneNumber, postalCodeId: parseInt(directionData.postalCodeId), reference: directionData.reference, stateId: directionData.stateId, street: directionData.street,
    }
    if(type !== 'edit') {
      isOk = await dispatch(postDirectionData(data))
      if(!isOk) {
        addToast('Ocurrio un error en la petición, comunicate con el equipo de soporte!', { appearance: 'warning', autoDismiss: true })
        return
      }
      else {
        dispatch(setOpen(false, null))
        dispatch(setPersonAddress(isOk))
      }
    }
    else {
      isOk = await dispatch(putDirectionData(data, directionData.id))
      if(!isOk) {
        addToast('Comunicarte con Misael', { appearance: 'error', autoDismiss: true })
        return
      }
      else dispatch(setOpen(false, null))
    } 
  }
  // const handleForm = async(event, prop, type, index, step) => {
  //   if(step === 3) {
  //     if(type === 'create') setDirectionData({ 'street': '' , 'number': '', 'internalNumber': '', 'countryCode': '', 'postalCodeId': '',  'state': '', 'neighborhood': '',  'stateId': '', 'countyId': '', 'localityId': '', 'locality': '', 'reference': '', 'phoneNumber': '', 'email': '', 'personId': person.personId })
  //     if(type === 'add') setDirectionData({ 'street': '' , 'number': '', 'internalNumber': '', 'countryCode': '', 'postalCodeId': '',  'state': '', 'neighborhood': '',  'stateId': '', 'countyId': '', 'localityId': '', 'locality': '', 'reference': '', 'phoneNumber': '', 'email': '', 'personId': person.personId })
  //     if(type === 'edit') {
  //       let responsePostalCode = await dispatch(getMexicoPostalCode(personAddress[index].postalCodeId, 'MX'));
  //       console.log(responsePostalCode)
  //       const address = {
  //         ...personAddress[index],
  //         state: responsePostalCode[0].state.name,
  //         locality: responsePostalCode[0].locality.name
  //       }
  //       await setDirectionData(address);
  //     }
  //     dispatch(setOpen(event, prop, type))
  //   }
  //   else dispatch(setOpen(event, prop))
  // }
  const selectDirectionList = (prop) => {
   
  }
  return { init, needInvoice, setNeedInvoice, fiscalId, creditPaymentOption, formatCurrency, setCreditPaymentOption, setFiscalId, searcFiscalID, fiscalData, fiscalD, postalCodes, postalCode, directionData, isAgree, setIsAgree, cart, showParticipantsCards, setShowParticipantsCards, creditPaymentOptions, handleStep, handleInvoice, selectDirectionList, handleInvoiceForm, handleInvoiceData, handleDirectionData, handleDirection, handleDirectionForm, directionItem, handlePostalCodeDirectionData, handlePostalCodeInvoiceData, totalAmount, format, goToCardPayment, isFormatPayment, currencyCode, handleDirectionStep, countries, cuponActive, setCuponActive}
}

export default usePayment
