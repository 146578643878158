import React from "react";
import { Link } from 'react-router-dom'
import bookImg from '../assets/mindware.jpg'
import { FunctionalIFrameComponent } from './Functional'
import FormatPayments from '../app/FormatPayment'
const FormatPayment = ({formatCurrency, cart, person, subTotal, currentUserCountryCode, format}) => {
  const isDepositPayment = true
  // const handlePrint = () => {
  //   // window.print('#printf');
  //   // var pri = document.getElementById(format.data.pdfUrl);
  //   // pri.document.open()
  //   // }  document.print(format.data.pdfUrl);
  //   let a = format.data.pdfUrl;
  //   a.print()
  // }
  // const handlePrint = (e) => {
  //   // e.preventDefault();  
  //   var myWindow=window.open('','','width=600,height=600');
  //   myWindow.document.write(<FormatPayments />);
    
  //   myWindow.document.close();
  //   myWindow.focus();
  //   myWindow.print();
  //   myWindow.close();
  // }
  return (
    <>
    
        {person  &&
        <div className="min-h-[50vh]">
          <h5 className="text-[1.6rem] font-medium">
            {/* <i className="fas fa-shopping-cart"></i> */}
            Resumen de compra
          </h5>
          {cart.map((product, index) => {
            return (
              <>
                <div className="bg-white shadow-xl rounded-[14px] px-[16px] pt-[8px] pb-[8px] text-blackASC w-fullitems-center flex py-[20px] my-[20px] flex flex-col justify-between" key={index}>
                  <div className="flex flex-row items-center">
                    <img src={product.image || bookImg} className="w-[5rem] mr-[1rem]" alt="..." />
                    <div className="w-full">
                      <h5 className="text-[1.6rem] font-medium">
                        {product.name}
                      </h5>
                      Precio unitario:
                      {product.discount > 0 
                        ?
                          <>
                            <span className="price price-old mr-2">
                              {/* {formatCurrency(product.price.amount)} */}
                            </span>
                            <span className="price price-new">
                              {/* {formatCurrency(
                                product.price.amount, product.price.currencyCode
                              )} */}
                            </span>
                          </>
                        :
                          <span className="price">
                            {formatCurrency(product.price.amount)}
                          </span>
                      }
                          <br />
                          Cantidad: {product.cartQuantity}
                    </div>
                    <div className="">
                        <h5 className="text-danger">
                          {formatCurrency(product.cartQuantity * product.price.amount)}
                        </h5>
                    </div>
                  </div>
                </div>
                <div className="card px-3 mt-0">
                <div className="row mt-0 py-1">
                  <div className="col-md d-flex align-items-center justify-content-start">
                    <a href="/shop/cart">
                      Editar carrito
                    </a>
                  </div>
                  <div className="col-md d-flex align-items-center justify-content-end">
                    <h5 className="text-danger py-0 my-0">
                      Subtotal:  {formatCurrency(product.price.amount)}
                    </h5>
                  </div>
                </div>
                </div>
              </>
            )
          })}

          {/* <div className="card px-3 mt-0">
            <div className="row mt-0 py-1">
              <div className="col-md d-flex align-items-center justify-content-start">
                <a href="/shop/cart">
                  <span className="badge badge-pill badge-primary">
                    <i className="fas fa-shopping-cart"></i>
                    Editar carrito
                  </span>
                </a>
              </div>
              <div className="col-md d-flex align-items-center justify-content-end">
                <h5 className="text-danger py-0 my-0">
                  Subtotal:  {formatCurrency(product.price.amount)}
                </h5>
              </div>
            </div>
          </div> */}

          {/* <template v-if="isDepositPayment"> */}
          {isDepositPayment &&
          <>
            {/* {currentUserCountryCode == 'MX' && */}
              <div className="bg-blueNotice px-2 py-1 mb-[20px] shadow-xl rounded-[14px] px-[16px] pt-[8px] pb-[8px]">
                <p className='p-0'>
                  <strong> Importante:</strong>
                  Si paga con ficha de depósito, la factura llegará en el transcurso de
                  24 a 48 horas después de confirmado el pago.
                </p>
              </div>
            {/* } */}

            <div className="flex flex-col justify-between">
                <div>
                  {/* <button onClick={()=>handlePrint()} className=""  target="_blank">
                    Imprimir
                  </button> */}
                  <a className="underline decoration-2 ml-[10px]" rel="noreferrer" href={format.data.pdfUrl} target="_blank">
                    Descargar
                  </a>
                  <div>
                </div>
                {/* {format.pdfUrl && */}
                  <div className="row" >
                    {/* <iframe
                      className="frame w-full"
                      name="voucher"
                      width="100%"
                      height="700px"
                      // src="./shop/format"
                      
                      title="Format Payment Error"
                      >
                      <p>Tu browser no soporta iFrames</p>
                      </iframe> */}
                    {/* <iframe
                      className="frame w-full"
                      name="voucher"
                      width="100%"
                      height="700px"
                      src='/format-payment'
                      title="Format Payment Error"
                      >
                      <p>Tu browser no soporta iFrames</p>
                      </iframe> */}
                      <FunctionalIFrameComponent title="functional-iframe">
                        <FormatPayments formatCurrency={formatCurrency} format={format} />
                      </FunctionalIFrameComponent>
                      {/* <iframe format={format} ref={format}>
                        {true && createPortal(children, mountNode)}
                      </iframe> */}
                  </div>
                {/* } */}
                </div>
            </div>

            {/* {false
              ?
                <div className="row" >
                  <iframe
                    className="frame"
                    name="voucher"
                    width="100%"
                    height="700px"
                    src="/shop/format"
                    title="Format Payment Error"
                    >
                    <p>Tu browser no soporta iFrames</p>
                    </iframe>
                </div>
              :
                <>
                  {creditPaymentOption && <h4>
                      Pago en plataforma de Banco a {creditPaymentOption} meses
                    </h4>
                  }
                  {currencyPayment !== 'MXN' &&
                    <h4>
                      Pago en plataforma de Banco será realizado en Dólar Americano
                    </h4>
                  }
                  {creditPaymentOption === false && currencyPayment === 'MXN' &&
                    <h4>
                      Pago en plataforma de Banco
                    </h4>
                  }
                  <div className="row">
                    <div className="col-lg text-center">
                      <iframe
                        className="frame"
                        name="payment"
                        width="100%"
                        height="700px"
                        src={format.pdfUrl}
                        title="Format Payment"
                      >
                        <p>Tu browser no soporta iFrames</p>
                      </iframe>
                    </div>
                  </div>
                </>
            } */}
          </>}

          <div className="row mx-2 mt-4 mb-4">
            <div className="col-lg text-center">
              <Link
                type="primary w-50"
                size="lg"
                to="/shop"
                className="underline decoration-2 ml-[10px]"
                // onClick={() => goToTab('showPaymentForm')}
              >
                Regresar
              </Link>
            </div>
          </div>
        </div>}
    </>
  )
}
export default FormatPayment
