import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/css';
// SwiperCore.use([Autoplay]);
const Slider = () => {
  SwiperCore.use([Autoplay]);
  return (
    <Swiper
      className=''
      spaceBetween={50}
      slidesPerView={1}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
      <SwiperSlide className='w-full min-h-[240px] bg-bluePrimary rounded-[15px] flex items-center justify-center text-white'>Slide 1</SwiperSlide>
      <SwiperSlide className='w-full min-h-[240px] bg-bluePrimary rounded-[15px] flex items-center justify-center text-white'>Slide 2</SwiperSlide>
      <SwiperSlide className='w-full min-h-[240px] bg-bluePrimary rounded-[15px] flex items-center justify-center text-white'>Slide 3</SwiperSlide>
      <SwiperSlide className='w-full min-h-[240px] bg-bluePrimary rounded-[15px] flex items-center justify-center text-white'>Slide 4</SwiperSlide>
    </Swiper>
  );
};
export default Slider