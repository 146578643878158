import React, {useEffect} from 'react'
import logoASC from '../assets/logo.svg'
import logoASCPagos from '../assets/logo.svg'
import { Link } from 'react-router-dom'
const Header = () => {
 
  return (
    <>
      <header className="py-[15px] px-[20px] flex justify-between text-gray shadow-xl shadow-gray-600 z-[90!important]">
        <Link to='/'>
          <img src={logoASC} className='h-[40px]' alt="Logo" />
        </Link>
      </header>
    </>
  )
}
export default Header
