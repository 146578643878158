export const API_CONFIG = {
	/* localhost */
	// TARIFF_ID: 3,
	// ACADEMIC_YEAR_ID: 6,
	// RECOVER_PASS_URL: 'https://login.ascc.me/',
	// INVOICING_URL: 'https://facturacion.ascc.me/',
	// API_URL: 'http://localhost:3000/',
	// APP_ID: "479bee78-246c-45de-842d-b33a90c53e7e",
	// APP_API_FUNCTIONS_URL: "https://us-central1-asc-cloud-functions-dev.cloudfunctions.net/"
		/* localhost mision */
	/* PRODUCTION */
	TARIFF_ID: 3,
	ACADEMIC_YEAR_ID: 6,
	RECOVER_PASS_URL: 'https://login.ascc.me/',
	INVOICING_URL: 'https://facturacion.ascc.me/',
	API_URL: 'https://asc-api-go.herokuapp.com/',
	APP_ID: "5c99c29f-97a8-4387-839f-70378abf556f",
	/* Develop */
		// TARIFF_ID: 3,
		// ACADEMIC_YEAR_ID: 6,
		// RECOVER_PASS_URL: 'https://login.ascc.me/',
		// INVOICING_URL: 'https://facturacion.ascc.me/',
		// API_URL: 'https://asc-api-dev.herokuapp.com/',
		// APP_ID: "a9412c5f-d254-40b6-a2d4-c044e5b303f0",
};
